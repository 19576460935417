import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {LANGUAGE_STYLESHEET_MAP} from '../common/language-stylesheet';
import { Settings } from '../models/settings';

@Injectable()
export class SettingsService {
  private language: BehaviorSubject<string>;
  public language$: Observable<string>;

  private settingsSource = <any> new BehaviorSubject('');
  public settings = this.settingsSource.asObservable();

  constructor(private translateService: TranslateService) {
    this.language = new BehaviorSubject(this.getLanguage());
    this.language$ = this.language.asObservable();
    this.setLanguage(this.getLanguage());
  }

  getLanguage() {
    let lang = localStorage.getItem('lang');
    if (!lang) {
      lang = 'en'; // Default language
    }
    return lang;
  }

  getLangStylesheetUrl() {
    return LANGUAGE_STYLESHEET_MAP[this.getLanguage()];
  }

  setLanguage(lang) {
    localStorage.setItem('lang', lang);
    this.translateService.use(lang);
    this.language.next(lang);
  }

  getAlignment() {
    if (this.getLanguage() === 'en') {
      return 'left';
    } else {
      return 'right';
    }
  }

  setSettings(settings:Settings) {
    this.settingsSource.next(settings);
  }
}
