import {AfterViewInit, Component, OnInit, Output, EventEmitter, PLATFORM_ID} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ConfigSettings} from './config/config.settings';
import {FooterService} from './config/footer.service';
import {Store} from './models/store';
import {ConfigService} from './config/config.service';
import {LocalStorage} from '@ngx-pwa/local-storage';

import {HostListener, Inject} from '@angular/core';
import {DOCUMENT, ViewportScroller, isPlatformBrowser} from '@angular/common';
import {SettingsService} from './services/settings-service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { fromEvent, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NavbarService } from './config/navbar.service';
import {MatDialog} from '@angular/material/dialog';
import { StoreDialogComponent } from './pages/store-dialog/store-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  lang: string;
  footerStatus: boolean;
  stores: Store[] = [];
  store: Store;
  htmlTag: any;
  public langStylesheetUrl: SafeResourceUrl = null;
  isBrowser: boolean;
  dataLoaded: boolean = false;

  public constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private settingsService: SettingsService,
    private configSettings: ConfigSettings,
    private configService: ConfigService,
    private footerService: FooterService,
    protected localStorage: LocalStorage,
    public sanitizer: DomSanitizer,
    private viewportScroller: ViewportScroller,
    private navBarService: NavbarService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    // AppComponent.isBrowser.next(isPlatformBrowser(platformId));

    this.isBrowser = isPlatformBrowser(platformId);
    this.readAllStores();
    this.translateService.setDefaultLang('en');
    this.htmlTag = this.document.getElementsByTagName(
      'html'
    )[0] as HTMLHtmlElement;

    fromEvent(window, 'scroll').pipe(
      //debounceTime(250),
      //distinctUntilChanged()
    ).subscribe(() => {
      let scrollTop = window.scrollY || document.documentElement.scrollTop;
      
      if(this.navBarService.emitScrollEvent) {
        this.navBarService.scrollChange.emit(scrollTop);
      }
    })
  }

  ngOnInit() {
    setTimeout(() => {
        this.dataLoaded = true;
    }, 2000);

    this.lang = this.settingsService.getLanguage();
    this.changeHtmlDirection();
    this.settingsService.language$.subscribe((lang) => {
      this.lang = lang;
      this.changeHtmlDirection();
      this.langStylesheetUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        `assets/css/${this.settingsService.getLangStylesheetUrl()}`);
    });
  }

  changeHtmlDirection() {
    this.htmlTag.dir = (this.lang === 'ar') ? 'rtl' : 'ltr';
  }

  ngAfterViewInit() {
    
  }

  readAllStores() {
    this.configService.readRequest('stores', {})
      .subscribe(response => {
        if (response.body.data) {
          this.stores = <Store[]>response.body.data;

          this.localStorage.getItem<any>('store_object').subscribe((store_object) => {
            /*
            * If Local Storage is set
            * then set store object
            * else
            * Set kuwait as default store
            * */
            if (store_object) {
              this.store = <Store>store_object;
            } else {
              // const dialogRef = this.dialog.open(StoreDialogComponent, {
              //   data: {stores: this.stores},
              //   width: '335px'
              // });
              // console.log('this.stores', this.stores);
              if (!this.isBrowser) {
                let isoCode = 'KW';
                this.countryDetails(isoCode);
              } else {
                this.findCountry().then(response => {
                  let isoCode = 'KW';
                  if(response.country) {
                    isoCode = response.country;
                    if (isoCode != 'KW' && isoCode != 'SA' && isoCode != 'QA' && isoCode != 'BH' && isoCode != 'OM' && isoCode != 'AE') {
                      isoCode = 'US';
                    }
                  }
                  this.countryDetails(isoCode);
                  // this.getCountryDetails(isoCode).then(store => {
                  //   if (store.status === 200) {
                  //     var selectedStore = store.data;
                  //     this.setLocalStore(selectedStore);
                  //     this.store = <Store>selectedStore;
                  //   } else {
                  //     let index = this.stores.findIndex(store => store.iso_code === 'KW');
                  //     let selectedStore = this.stores[index];
                  //     this.setLocalStore(selectedStore);
                  //     this.store = <Store>selectedStore;
                  //   }
                  // });
                });
              }
            }
            /* Removing selected store from stores array*/
            //const index = this.stores.findIndex(store => store.id === this.store.id);
            //this.stores.splice(index, 1);
          });
        }
      });
  }

  countryDetails(isoCode: string) {
    this.getCountryDetails(isoCode).then(store => {
      if (store.status === 200) {
        var selectedStore = store.data;
        this.setLocalStore(selectedStore);
        this.store = <Store>selectedStore;
      } else {
        let index = this.stores.findIndex(store => store.iso_code === 'KW');
        let selectedStore = this.stores[index];
        this.setLocalStore(selectedStore);
        this.store = <Store>selectedStore;
      }
    });
  }

  findCountry(): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
      this.configService.getLocation()
        .subscribe(response => {
          if (response.body) {
            resolve(response.body);
          }
        });
    });

    return promise;
  }

  getCountryDetails(iso_code): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
      const params = {
        iso_code: iso_code
      };
      this.configService.readRequest('country-details', params)
        .subscribe(response => {
          if (response.body.data) {
            resolve(response.body);
          }
        });
    });

    return promise;
  }

  setLocalStore(selectedStore: any) {
    this.localStorage.setItem('store_object', selectedStore).subscribe(() => {
    });
  }
}
