import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Location} from '@angular/common';
import {Store} from '../../models/store';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {ConfigSettings} from '../../config/config.settings';
import {SettingsService} from '../../services/settings-service';

@Component({
  selector: 'store-dialog',
  templateUrl: './store-dialog.component.html',
  styleUrls: ['./store-dialog.component.css']
})
export class StoreDialogComponent implements OnInit {

  lang: string;
  stores: Store[] = [];
  selectedLanguage:any = '';
  storeId: number = 0;
  selectedStore: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    protected localStorage: LocalStorage,
    private settingsService: SettingsService,
    private dialogRef: MatDialogRef<StoreDialogComponent>,
    private configSettings: ConfigSettings
  ) {
  }

  ngOnInit() {
    this.lang = this.configSettings.getLang();
    console.log('this.data', this.data);
    if (this.data != undefined) {
      this.stores = this.data.stores;
    }
  }

  setLanguage(language: any) {
    this.selectedLanguage = language;
    if(this.selectedLanguage != '' && this.storeId != 0){
      this.continueShopping();
    }
  }

  changeStore(event: any, id: number) {
    const index = this.stores.findIndex(store => store.id === id);
    this.selectedStore = this.stores[index];
    this.storeId = id;
    this.setLocalStore(this.selectedStore);
    if(this.selectedLanguage != '' && this.storeId != 0){
      this.continueShopping();
    }
  }

  setLocalStore(selectedStore: any) {
    this.localStorage.setItem('store_object', selectedStore).subscribe(() => {
      this.storeId = selectedStore.id;
      // window.location.reload();
      //window.location.href = window.location.href;
    });
  }

  continueShopping() {
    this.settingsService.setLanguage(this.selectedLanguage);
    // this.setLocalStore(this.selectedStore);
    this.dialogRef.close();
    window.location.reload();
  }
}
