<link rel="stylesheet" [href]='langStylesheetUrl'>

<div class="page-wrapper">
    <app-header [stores]="stores" [store]="store"></app-header>
    <main class="main" style="min-height:550px">
        <router-outlet></router-outlet>
    </main>
    <!-- <app-footer></app-footer> -->
    <app-footer *ngIf="dataLoaded"></app-footer>
</div>

<app-loading></app-loading>

<!--<router-outlet></router-outlet>-->


