<div class="wrapper-404">
    <h6></h6>
    <div class="col-12 products mb-3 pr-0" class="col-md-12">
        <ng-container>
            <div class="empty-cart">
                <div class="empty-cart-content white-container text-center pt-5 pb-5">
                    <figure class="empty py-5">
                        <img src="assets/sass/icons/not_found.png" alt="NOT FOUND" class="m-auto">
                    </figure>
                    <h4 class="page-title font-bold text-center">{{'NOT FOUND' | translate}}</h4>
                    <p class="font-regular text-center">
                        {{"The page you are looking for was not found." | translate}}
                    </p>
                </div>
            </div>
        </ng-container>
    </div>
</div>

