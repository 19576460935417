<footer class="footer" style="background-color: #1a1919; color: #fff;">
    <div class="footer-middle pl-4 pr-4 pl-sm-5 pr-sm-5">
        <div class="container">
            <div class="row pl-3 pr-3">
                <div class="col-12 col-sm-4 col-lg-3 pl-0 pr-0 pl-sm-3 pr-sm-3">
                    <div class="col-sm-12 col-lg-9 footer-logo-pos p-0">
                        <div class="widget-about d-flex align-items-center gap-10 max-width-93">
                            <a [routerLink]="'/home'" class="logo-container">
                                <img src="assets/sass/icons/smoke_and_soda_logo.jpeg" alt="Smoke and Soda" border="0" class="border-radius-10 f-logo">
                            </a>
                            <div class="p-1 app-icons">
                                <a href="https://play.google.com/store/apps/details?id=com.codelab.sns" class="d-block mb-1">
                                    <img src="assets/sass/icons/google_play_app.png" alt="Smoke and Soda"  class="border-radius-10" border="0">
                                </a>
                                <a href="https://apps.apple.com/in/app/smoke-and-soda/id1662317287" class="">
                                    <img src="assets/sass/icons/apple_play_app.png" alt="Smoke and Soda"  class="border-radius-10" border="0">
                                </a>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-9 p-0 mt-2 mb-4">
                        <h1 class="download-app" >{{'JOIN OUR EXPERIENCE' | translate}}</h1>
                    </div>
                    <!-- End .widget about-widget -->
                </div><!-- End .col-sm-6 col-lg-3 -->

                <div class="col-12 col-sm-4 col-lg-2 pl-0 pr-0 pl-sm-3 pr-sm-3">
                    <div class="widget">
                        <h4 class="widget-title">{{'Information' | translate}}</h4><!-- End .widget-title -->

                        <ul class="widget-list font-size-2">
                            <li class="mb-0"><a href="javascript:;" [routerLink]="'/page/1/about-us'" title="{{'About Us' | translate}}">{{'About Us' | translate}}</a></li>
                            <li class="mb-0"><a href="javascript:;" [routerLink]="'/faq'" title="{{'FAQ' | translate}}">{{'FAQ' | translate}}</a></li>
                            <li class="mb-0"><a href="javascript:;" [routerLink]="'/contact-us'" title="{{'Contact us' | translate}}">{{'Contact us' | translate}}</a></li>
                            <li class="mb-0"><a href="javascript:;" [routerLink]="'/join-us'" title="{{'Sell with us' | translate}}">{{'Sell with us' | translate}}</a></li>
                            <li class="mb-0"><a href="javascript:;" [routerLink]="'/careers'" title="{{'Careers' | translate}}">{{'Careers' | translate}}</a></li>
                        </ul><!-- End .widget-list -->

                    </div><!-- End .widget -->
                </div><!-- End .col-sm-6 col-lg-3 -->

                <div class="col-12 col-sm-4 col-lg-2 pl-0 pr-0 pl-sm-3 pr-sm-3">
                    <div class="widget">
                        <h4 class="widget-title">{{'Customer Service' | translate}}</h4><!-- End .widget-title -->

                        <ul class="widget-list font-size-2">
                            <li class="mb-0"><a href="javascript:;" [routerLink]="'/page/4/return-policy'" title="{{'Return & Exchange Policy' | translate}}">{{'Return & Exchange Policy' | translate}}</a></li>
                            <li class="mb-0"><a href="javascript:;" [routerLink]="'/page/6/shipping-policy'" title="{{'Shipping and Delivery' | translate}}">{{'Shipping and Delivery' | translate}}</a></li>
                            <li class="mb-0"><a href="javascript:;" [routerLink]="'/page/2/terms-and-conditions'" title="{{'Terms and conditions' | translate}}">{{'Terms and conditions' | translate}}</a></li>
                            <li class="mb-0"><a href="javascript:;" [routerLink]="'/page/5/privacy-policy'" title="{{'Privacy Policy' | translate}}">{{'Privacy Policy' | translate}}</a></li>
                        </ul><!-- End .widget-list -->
                    </div><!-- End .widget -->
                </div><!-- End .col-sm-6 col-lg-3 -->

                <div class="col-12 col-sm-4 col-lg-2 pl-0 pr-0 pl-sm-3 pr-sm-3">
                    <div class="widget">
                        <h4 class="widget-title">{{'My Account' | translate}}</h4><!-- End .widget-title -->

                        <ul class="widget-list font-size-2">
                            <li class="mb-0"><a href="javascript:;" (click)="navigateToAccountLinks('my-account')" title="{{'Account' | translate}}">{{'Account' | translate}}</a></li>
                            <li class="mb-0"><a href="javascript:;" (click)="navigateToAccountLinks('wishlist')" title="{{'Wishlist' | translate}}">{{'Wishlist' | translate}}</a></li>
                            <li class="mb-0"><a href="javascript:;" (click)="navigateToAccountLinks('my-orders')" title="{{'Orders' | translate}}">{{'Orders' | translate}}</a></li>
                        </ul><!-- End .widget-list -->
                    </div><!-- End .widget -->
                </div><!-- End .col-sm-4 col-lg-2 -->
                <div class="col-12 col-sm-6 col-lg-3 pl-0 pr-0 pl-sm-3 pr-sm-3">
                    <div class="widget widget-newsletter">
                        <h4 class="widget-title">{{'Sign up to newsletter'| translate}}</h4><!-- End .widget-title -->

                        <!-- <p>Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan.</p> -->
                        
                        <form [formGroup]="newsletterForm" (ngSubmit)="onFormSubmit()" *ngIf="newsletterForm">
                            <div class="input-group">
                                <input type="email" class="form-control" placeholder="{{'Enter your Email Address' | translate}}" aria-label="Email Adress" formControlName="email" required>
                                <div class="input-group-append">
                                    <button class="btn btn-dark" type="submit"><i [ngClass]="[lang == 'en' ? 'icon-long-arrow-right' : 'icon-long-arrow-left' ]"></i></button>
                                </div><!-- .End .input-group-append -->
                            </div><!-- .End .input-group -->
                            <small id="emailHelp" class="text-danger"
                                *ngIf="newsletterForm.controls['email'].touched  && !newsletterForm.controls['email'].valid">
                                {{'Please enter your email address' | translate}}
                            </small>
                            <small id="emailHelp" class="text-success" *ngIf="message">
                                {{message | translate}}
                            </small>
                        </form>
                        <div class="social-icons social-icons-color" *ngIf="settings">
                            <a href="{{settings.instagram_link}}" class="social-icon social-instagram" title="Instagram" target="_blank">
                                <img src="assets/sass/icons/instagram@3x.png" />
                            </a>
                            <a href="{{settings.youtube_link}}" class="social-icon social-snapchat" title="Youtube" target="_blank">
                                <img src="assets/sass/icons/youtube@3x.png" />
                            </a>
                            <a href="{{settings.tiktok_link}}" class="social-icon social-tiktok" title="Tiktok" target="_blank">
                                <img src="assets/sass/icons/tiktok@3x.png" />
                            </a>
                            <a href="{{settings.twitter_link}}" class="social-icon social-twitter" title="X" target="_blank">
                                <img src="assets/sass/icons/X.png" />
                            </a>
                            <!-- <a href="{{settings.facebook_link}}" class="social-icon social-facebook" title="Facebook" target="_blank">
                                <img src="assets/sass/icons/twitter@3x.png" />
                            </a> -->
                        </div><!-- End .soial-icons -->

                    </div><!-- End .widget -->
                </div><!-- End .col-sm-6 col-lg-2 -->
            </div><!-- End .row -->
        </div><!-- End .container -->
    </div><!-- End .footer-middle -->

    <div class="footer-bottom pl-4 pr-4 pl-sm-5 pr-sm-5">
        <div class="container">
            <p class="footer-copyright">{{'Copyright © 2024 Smoke and Soda General Trading Company WLL. All Rights Reserved.' | translate}}</p><!-- End .footer-copyright -->
            <figure class="footer-payments d-flex" [ngClass]="[lang == 'en' ? 'm-auto ml-lg-auto mr-lg-0' : 'm-auto ml-lg-0 mr-lg-auto']">
                <img src="assets/sass/icons/knet@4x.png" alt="Knet">
                <img class="mx-2" src="assets/sass/icons/visa@4x.png" alt="Visa">
                <img src="assets/sass/icons/mastercard@4x.png" alt="mastercard">
                <img src="assets/sass/icons/applepay@3x.png" alt="Apple Pay">
                <!-- <img src="assets/sass/icons/samsung@4x.png" alt="Samsung Pay"> -->
            </figure><!-- End .footer-payments -->
            <!-- <figure class="footer-payments app-icons d-flex"> -->
                <!-- <a href="https://apps.apple.com/in/app/smoke-and-soda/id1662317287" target="_blank" title="Smoke and Soda App Download">
                    <img src="assets/sass/icons/applestore@4x.png" />
                </a> -->
                <!-- <a href="https://play.google.com/store/apps/details?id=com.codelab.sns" target="_blank" title="Smoke and Soda App Download">
                    <img src="assets/sass/icons/googlePlay@4x.png" />
                </a> -->
            <!-- </figure> -->
            <!-- End .footer-payments -->
        </div><!-- End .container -->
    </div><!-- End .footer-bottom -->
    <div id="whatsappchat">
        <!-- <a href="https://web.whatsapp.com/send?phone={{support_phone}}&amp;text=Hello! Welcome to Smoke and Soda! How may we help you?" target="_blank" class="btn-web bottom-right">
            <img src="assets/sass/icons/whatsapp.png" alt="WhatsApp Chat">
        </a>
        <a href="https://wa.me/{{support_phone}}?text=Hello! Welcome to Smoke and Soda! How may we help you?" target="_blank" class="btn-mobile bottom-right">
            <img src="assets/sass/icons/whatsapp.png" alt="WhatsApp Chat">
        </a> -->
    </div>
</footer>
