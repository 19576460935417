// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /* Dev Credentials */
  // apiurl: 'https://www.smokeandsoda.com/data/api/api-v3/',
  apiurl: 'https://snsweb.thecodelab.me/data/api/api-v3/', //used url
  // apiurl: 'http://snslocal.com/api/api-v3/',
  //apiurl: 'http://localhost:8080/api/api-v2/',
  authToken: 'CxQePjYA9cbEMzM3DJ3DhADwm3gaFByt6wN5eUBx3fAhLEskxNPr6kYSnwHs3cBd',
  authIv: 'vcuBQE034Kdf58vR',
  authKey: 'SMV6CemV344eGgfGVQwaP6W6MvDdb9rm',
  branchKey: '',
  title: 'SMOKE AND SODA',
  meta_desc: 'SMOKE AND SODA is an E-Commerce platform based in Kuwait dedicated to everyone who wants to take care about their look and style, and who are looking for tempting and comfortable essentials, offering the latest in quality Fashion, lifestyle products, Selfcare, beauty products, home décor, Collectables and more from all around the world.',
  meta_keywords: 'Smoke and Soda,ecommerce,Fashion,Outlet,discount,sale,clothes shoes,kids,accessories,namshi,boutiqaat,bazma,nalbes,riva',
  google_client_id: '410248077111-q52enhqfim8gltqg50f175df6h3522l5.apps.googleusercontent.com'
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
