<h5 class="text-center mb-1 text-uppercase select-store pb-1">{{'Select Country & Language' | translate}}</h5>
<div class="row m-0">
    <div class="col-12 p-0 pr-1">
        <ul class="store-list" [ngClass]="{'rtl': (lang == 'ar')}">
            <li *ngFor="let storeOption of stores">
                <a href="javascript:;" (click)="changeStore($event, storeOption.id)">
                    <span>
                        {{storeOption['name_en']}} ({{storeOption['currency_'+lang]}})
                    </span>
                    <span class="store-flag d-flex align-items-center">
                        <i class="icon-check ml-1 mr-1" *ngIf="storeId == storeOption['id']"></i>
                        <img src="{{storeOption['flag']}}" border="0" width="20"/>
                    </span>
                </a>
            </li>
        </ul>
        <ul class="store-language" [ngClass]="{'rtl': (lang == 'ar')}">
            <li>
                <a (click)="setLanguage('en')" class="language-change-link" href="javascript:;" [ngClass]="{'active': (selectedLanguage == 'en')}">
                    English
                </a>
            </li>
            <li>
                <a (click)="setLanguage('ar')" class="language-change-link" href="javascript:;" [ngClass]="{'active': (selectedLanguage == 'ar')}">
                العربية
                </a>
            </li>
        </ul>
        <!-- <button type="button" class="custom-button btn btn-outline-primary-2 w-100"
                (click)="continueShopping()">{{'Confirm Country & Language' | translate}}</button> -->
    </div>
</div>
