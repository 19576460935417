import {AbstractControl} from '@angular/forms';

export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any, fieldName?: any) {
    const config: any = {
      'required': fieldName + ' is required.',
      'min': 'Invalid value!',
      'max': 'Invalid value!',
      'invalidCreditCard': 'Is invalid credit card number',
      'invalidEmailAddress': 'Invalid email address',
      'invalidConfirmPassword': 'Invalid confirm password',
      'invalidPassword': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      'minlength': `Minimum length ${validatorValue.requiredLength}`
    };

    return config[validatorName];
  }

  static emailValidator(control) {
    // RFC 2822 compliant regex
    if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
      return null;
    } else {
      return {'invalidEmailAddress': true};
    }
  }

  static phoneValidator(control) {
    // RFC 2822 compliant regex
    if (control.value.match(/^[0-9+]+$/)) {
      return null;
    } else {
      return {invalidPhoneNumber: true};
    }
  }

  public static matchPassword(control: AbstractControl): { [key: string]: any } | null {
    const password = control.get('password').value;
    const confirmPassword = control.get('confirm_password').value;
    if (password !== confirmPassword) {
      control.get('confirm_password').setErrors({invalidConfirmPassword: true});
    } else {
      return null;
    }
  }

  public static matchNewPassword(control: AbstractControl): { [key: string]: any } | null {
    const password = control.get('new_password').value;
    const confirmPassword = control.get('confirm_password').value;
    if (password !== confirmPassword) {
      control.get('confirm_password').setErrors({invalidConfirmPassword: true});
    } else {
      return null;
    }
  }

  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return {'invalidPassword': true};
    }
  }

}
