import { NgModule } from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {DialogComponent} from './dialog.component';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatSnackBarModule} from '@angular/material/snack-bar';



@NgModule({
  declarations: [DialogComponent],
  imports: [
    SharedModule,
    MatDialogModule,
    NgSelectModule,
    MatSnackBarModule
  ],
  exports: [DialogComponent],
  providers: [{provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}}],
  entryComponents: [DialogComponent]
})
export class DialogModule { }
