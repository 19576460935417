import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {UserService} from '../config/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.isLoggedIn().then(() => {
      return true;
    }, () => {
      this.router.navigate(['/home'], {queryParams: {returnUrl: state.url}});
      return false;
    });
  }
}
