import {Component, OnInit} from '@angular/core';
import {ConfigSettings} from '../../../config/config.settings';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NavbarService} from '../../../config/navbar.service';
import {SettingsService} from '../../../services/settings-service';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {User} from '../../../models/user';
import {UserService} from '../../../config/user.service';
import {Router} from '@angular/router';
import {first} from 'rxjs/operators';
import { Store } from '../../../models/store';
import { Settings } from '../../../models/settings';

declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  support_email = '';
  support_phone = '';
  lang: string;
  userId: number;
  newsletterForm: any;
  submitted = false;
  hasError = true;
  message: string;
  store: Store;
  settings: Settings;

  constructor(
    private configSettings: ConfigSettings,
    private navbarService: NavbarService,
    private localStorage: LocalStorage,
    private settingsService: SettingsService,
    protected userService: UserService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.lang = this.settingsService.getLanguage();
    this.readSupportDetails();
    this.userService.getUserSession().then(response => {
      if (response) {
        this.userId = response.id;
      }

      let userId = (this.userId) ? this.userId : '';

      this.newsletterForm = new FormGroup({
        email: new FormControl('', Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
        ])),
        user_id: new FormControl(userId),
      });
    });
    this.configSettings.getStoreObject().then((result) => {
      this.store = <Store>result;
    });
  }

  readSupportDetails() {
    this.localStorage.getItem('support_details').subscribe((data: any) => {
      if (data) {
        this.support_email = data.support_email;
        this.support_phone = data.support_phone;
      } else {
        this.navbarService.currentSupportDetails.subscribe((observerData: any) => {
          if (observerData) {
            this.support_email = observerData.support_email;
            this.support_phone = observerData.support_phone;
          }
        });
      }
    });
    
    this.settingsService.settings.subscribe(data => this.settings = data);
  }

  navigateToAccountLinks(url: string) {
    if (this.userId) {
      this.router.navigate([url]);
    } else {
      $('#signin-modal').modal('show');
    }
  }

  onFormSubmit() {
    Object.keys(this.newsletterForm.controls).forEach(field => {
      const control = this.newsletterForm.get(field);
      control.markAsTouched({onlySelf: true});
    });
    if (this.newsletterForm.dirty && this.newsletterForm.valid) {
      const _form = this.newsletterForm.value;
      this.subscribe(_form);
    }
  }

  subscribe(formData: any) {
    if (this.newsletterForm.valid) {
      this.configSettings.toggleLoading(true);
      try {
        let params = {
          lang: this.lang,
          store: this.store.iso_code
        };
        this.userService.subscribe(params, formData)
          .pipe(first())
          .subscribe(
            response => {
              this.submitted = true;
              if (response.status === 200) {
                this.newsletterForm.reset();
                this.hasError = false;
                this.message = (this.lang === 'en') ? 'Thank you for subscribing with us.' : 'Thank you for subscribing with us.';
              } else if (response.status === 500) {
                this.hasError = true;
                this.message = (this.lang === 'en') ? 'There was an error processing the request. Please try again later.' : 'كان هناك خطأ في معالجة الطلب. الرجاء معاودة المحاولة في وقت لاحق.';
              }
              this.configSettings.toggleLoading(false);

              setTimeout(() => {
                this.submitted = false;
                this.hasError = false;
                this.message = '';
              }, 4000);
            }, error => {
            }
          );
      } catch (e) {
        this.configSettings.toggleLoading(false);
      }
    }
  }

}
