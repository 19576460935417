import {Injectable} from '@angular/core';
import {ConfigService} from './config.service';
import {CryptService} from './crypt.service';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {map} from 'rxjs/operators';
import {ConfigSettings} from './config.settings';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private configService: ConfigService,
    private configSettings: ConfigSettings
  ) {
  }

  login(username: string, password: string, lang: string, store: string) {
    const device = this.configSettings.getBrowserVersion();
    const params = {
      email: username,
      password: password,
      device_type: 'W',
      device_model: device,
      app_version: device,
      os_version: window.navigator.platform,
      lang: lang,
      store: store
    };
    const getParams = {
      lang: lang,
      store: store
    };
    return this.configService.postRequest('login', getParams, params)
      .pipe(map(response => {
        return response;
      }));
  }

  socialRegister(params: any) {
    const getParams = {
      lang: params.lang,
      store: params.store
    };
    return this.configService.postRequest('social-login', getParams, params)
      .pipe(map(response => {
        return response;
      }));
  }

  register(params: any) {
    const getParams = {
      lang: params.lang,
      store: params.store
    };
    return this.configService.putRequest('register', getParams, params)
      .pipe(map(response => {
        return response;
      }));
  }

  guestRegister(params: any) {
    return this.configService.putRequest('guest-register', {}, params)
      .pipe(map(response => {
        return response;
      }));
  }

  forgotPassword(params: any) {
    /*const params = {
      email: email,
    };*/
    return this.configService.postRequest('forgot-password', {}, params)
      .pipe(map(response => {
        return response;
      }));
  }

  editProfile(params: any) {
    return this.configService.putRequest('edit-profile', {}, params)
      .pipe(map(response => {
        return response;
      }));
  }

  changePassword(params: any) {
    return this.configService.postRequest('change-password', {}, params)
      .pipe(map(response => {
        return response;
      }));
  }

  contactUs(params: any) {
    return this.configService.postRequest('contact-us', {}, params)
      .pipe(map(response => {
        return response;
      }));
  }

  joinUs(params: any) {
    return this.configService.postRequest('join-us', {}, params)
      .pipe(map(response => {
        return response;
      }));
  }

  careers(params: any) {
    return this.configService.postRequest('careers', {}, params)
      .pipe(map(response => {
        return response;
      }));
  }

}
