import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './_guard/auth.guard';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home-new/home-new.module')
      .then(m => m.HomeNewModule),
    pathMatch: 'full'
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/coming-soon/coming-soon.module')
      .then(m => m.ComingSoonModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home-new/home-new.module')
      .then(m => m.HomeNewModule),
  },
  {
    path: 'home-new',
    loadChildren: () => import('./pages/home-new/home-new.module')
      .then(m => m.HomeNewModule),
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module')
      .then(m => m.ContactUsModule),
  },
  {
    path: 'join-us',
    loadChildren: () => import('./pages/join-us/join-us.module')
      .then(m => m.JoinUsModule),
  },
  {
    path: 'careers',
    loadChildren: () => import('./pages/careers/careers.module')
      .then(m => m.CareersModule),
  },
  {
    path: 'product/category',
    loadChildren: () => import('./pages/product-list/product-list.module')
      .then(mod => mod.ProductListModule),
  },
  {
    path: 'category',
    loadChildren: () => import('./pages/product-list/product-list.module')
      .then(mod => mod.ProductListModule),
  },
  {
    path: 'shop',
    loadChildren: () => import('./pages/product-list/product-list.module')
      .then(mod => mod.ProductListModule),
  },
  {
    path: 'product/brand',
    loadChildren: () => import('./pages/product-list/product-list.module')
      .then(mod => mod.ProductListModule),
  },
  {
    path: 'brand',
    loadChildren: () => import('./pages/product-list/product-list.module')
      .then(mod => mod.ProductListModule),
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/product-list/product-list.module')
      .then(mod => mod.ProductListModule),
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./pages/product-detail/product-detail.module')
      .then(mod => mod.ProductDetailModule)
  },
  {
    path: 'product',
    loadChildren: () => import('./pages/product-detail/product-detail.module')
      .then(mod => mod.ProductDetailModule)
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./pages/wishlist/wishlist.module')
      .then(mod => mod.WishlistModule), canActivate: [AuthGuard]
  },

  {
    path: 'page',
    loadChildren: () => import('./pages/cms/cms.module')
      .then(mod => mod.CmsModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module')
      .then(mod => mod.FaqModule)
  },
  {
    path: 'brands',
    loadChildren: () => import('./pages/brand-list/brand-list.module')
      .then(mod => mod.BrandListModule)
  },
  {
    path: 'boutiques',
    loadChildren: () => import('./pages/boutique-list/boutique-list.module')
      .then(mod => mod.BoutiqueListModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module')
      .then(mod => mod.ForgotPasswordModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module')
      .then(mod => mod.CartModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module')
      .then(mod => mod.CheckoutModule), canActivate: [AuthGuard]
  },
  {
    path: 'order-confirmation',
    loadChildren: () => import('./pages/order-confirmation/order-confirmation.module')
      .then(mod => mod.OrderConfirmationModule)
  },
  {
    path: 'user-account-menu',
    loadChildren: () => import('./pages/user-account-menu/user-account-menu.module')
      .then(mod => mod.UserAccountMenuModule), canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/account/account.module')
      .then(mod => mod.AccountModule), canActivate: [AuthGuard]
  },
  {
    path: 'my-account',
    loadChildren: () => import('./pages/my-account/my-account.module')
      .then(mod => mod.MyAccountModule), canActivate: [AuthGuard]
  },
  {
    path: 'my-orders',
    loadChildren: () => import('./pages/my-orders/my-orders.module')
      .then(mod => mod.MyOrdersModule), canActivate: [AuthGuard]
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./pages/order-detail/order-detail.module')
      .then(mod => mod.OrderDetailModule), canActivate: [AuthGuard]
  },
  {
    path: 'my-address',
    loadChildren: () => import('./pages/my-address/my-address.module')
      .then(mod => mod.MyAddressModule), canActivate: [AuthGuard]
  },
  {
    path: 'my-loyalty-points',
    loadChildren: () => import('./pages/my-loyalty-points/my-loyalty-points.module')
      .then(mod => mod.MyLoyaltyPointsModule), canActivate: [AuthGuard]
  },
  {
    path: 'my-wallet',
    loadChildren: () => import('./pages/my-wallet/my-wallet.module')
      .then(mod => mod.MyWalletModule), canActivate: [AuthGuard]
  },
  {
    path: 'editorials',
    loadChildren: () => import('./pages/editorials/editorials.module')
      .then(mod => mod.EditorialsModule)
  },
  {
    path: 'editorial-details',
    loadChildren: () => import('./pages/editorial-details/editorial-details.module')
      .then(mod => mod.EditorialDetailsModule)
  },
  {
    path: 'bundles',
    loadChildren: () => import('./pages/bundles/bundles.module')
      .then(mod => mod.BundlesModule)
  },
  {
    path: 'bundle-cart',
    loadChildren: () => import('./pages/cart/cart.module')
      .then(mod => mod.CartModule)
  },
  {
    path: 'page-not-found', component: PageNotFoundComponent
  }


  /*{path: 'shipping-address/add', component: ShippingAddressFormComponent, canActivate: [AuthGuard]},
  {path: 'shipping-address/:id/edit', component: ShippingAddressFormComponent, canActivate: [AuthGuard]},
  {path: 'shipping-address', component: ShippingAddressComponent, canActivate: [AuthGuard]},
  {path: 'payment', component: PaymentComponent, canActivate: [AuthGuard]},
  {path: 'order-confirmation', component: OrderConfirmationComponent, canActivate: [AuthGuard]},
  {path: 'my-account', component: MyAccountComponent, canActivate: [AuthGuard]},
  {path: 'account', component: AccountComponent, canActivate: [AuthGuard]},
  {path: 'my-orders', component: MyOrdersComponent, canActivate: [AuthGuard]},
  {path: 'order/detail/:id', component: OrderDetailComponent, canActivate: [AuthGuard]},
  {path: 'my-address', component: MyAddressComponent, canActivate: [AuthGuard]},
  {path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard]},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'address/add', component: AddressComponent, canActivate: [AuthGuard]},
  {path: 'address/edit/:id', component: AddressComponent, canActivate: [AuthGuard]},
  {path: 'shops', component: ShopListComponent},
  {path: 'deep-link', component: DeepLinkComponent},
  {path: 'return-request-list', component: ReturnRequestListComponent, canActivate: [AuthGuard]},
  {path: 'return-request/detail/:id', component: ReturnRequestDetailComponent, canActivate: [AuthGuard]},
  {path: 'register', component: RegisterComponent},*/

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy', preloadingStrategy: PreloadAllModules})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
