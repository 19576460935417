import {HttpHeaders} from '@angular/common/http';
import {EventEmitter, Injectable, Output, Directive} from '@angular/core';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Store} from '../models/store';
import {catchError, delay, map, mergeMap, retry, retryWhen, take} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import { Category } from '../models/category';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class ConfigSettings {
  public static ENCRYPT_KEY = 'Leza2021';
  loading = false;
  currency: string;
  store: Store;
  defaultIsoCode: string = 'KW';
  @Output() load: EventEmitter<boolean> = new EventEmitter<boolean>();
  private categorySource = <any> new BehaviorSubject('');
  public categories = this.categorySource.asObservable();

  constructor(
    protected localStorage: LocalStorage
  ) {
  }

  cleanUrl(url: string) {
    return url.trim().toLowerCase().replace(/[^a-zA-Z\d\s]/, '').replace(' ', '-');
  }

  getLang() {
    let lang = localStorage.getItem('lang');
    if (!lang) {
      lang = 'en'; // Default language
    }
    return lang;
    // return localStorage.getItem('lang');
  }

  setLang(lang) {
    localStorage.setItem('lang', lang);
  }

  getStore() {
    return localStorage.getItem('store');
  }

  setStore(store) {
    localStorage.setItem('store', store);
  }

  getStoreObject(): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
      const storeObj = this.localStorage.getItem<any>('store_object').pipe(
        mergeMap(result => {
          if (!result) {
            return throwError('Error');
          }

          return of(result);
        }),
        //retry(1500)
        retryWhen(error => error.pipe(delay(500), take(10)))
      );
      storeObj.subscribe({
        next(obj) {
          this.store = <Store>obj;
          resolve(this.store);
        },
        error(error) {
          console.log(error);
        }
      });
      /*this.localStorage.getItem<any>('store_object').subscribe((store_object) => { console.log(store_object);
          if (store_object) {
              this.store = <Store> store_object;
              resolve(this.store);
          }
      });*/
    });
    return promise;
  }

  getCurrency() {
    return 'KD';
  }

  setCurrency(currency) {
    localStorage.setItem('currency', currency);
  }

  getCartId() {
    return localStorage.getItem('cart-id');
  }

  setCartId(cart_id) {
    localStorage.setItem('cart-id', cart_id);
  }

  getBundleId() {
    return localStorage.getItem('bundle-id');
  }

  setBundleId(bundleId) {
    localStorage.setItem('bundle-id', bundleId);
  }

  toggleLoading(key: boolean) {
    this.loading = key;
    this.load.emit(this.loading);
  }

  getCartCount() {
    if (localStorage.getItem('cart-count') === null) {
      this.setCartCount(0);
    }
    return localStorage.getItem('cart-count');
  }

  setCartCount(cart_count) {
    localStorage.setItem('cart-count', cart_count);
  }

  getWishlistCount() {
    if (localStorage.getItem('wishlist-count') === null) {
      this.setWishlistCount(0);
    }
    return localStorage.getItem('wishlist-count');
  }

  setWishlistCount(cart_count) {
    localStorage.setItem('wishlist-count', cart_count);
  }

  getBrowserVersion() {
    let ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {
        return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return M.join(' ');
  }

  roundUp(num, precision) {
    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
  }

  setCategories(categories:Category[]) {
    this.categorySource.next(categories);
  }

}
