import {NgModule} from '@angular/core';
import {FooterComponent} from './footer.component';
import {SharedModule} from '../../../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientJsonpModule} from '@angular/common/http';
import {NavbarService} from '../../../config/navbar.service';


@NgModule({
  declarations: [FooterComponent],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientJsonpModule
  ],
  exports: [
    FooterComponent
  ],
  providers: [
    NavbarService
  ]
})
export class FooterModule {
}
