import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, NgZone, PLATFORM_ID, Inject} from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Category} from '../../../models/category';
import {Store} from '../../../models/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Suggestion} from '../../../models/suggestion';
import {Subject} from 'rxjs';
import {ConfigService} from '../../../config/config.service';
import {ConfigSettings} from '../../../config/config.settings';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CartService} from '../../../config/cart.service';
import {first} from 'rxjs/operators';
import {AuthenticationService} from '../../../config/authentication.service';
import {CryptService} from '../../../config/crypt.service';
import {ToastrService} from 'ngx-toastr';
import {ValidationService} from '../../../services/validation.service';
import {User} from '../../../models/user';
import {UserService} from '../../../config/user.service';
import {SettingsService} from '../../../services/settings-service';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../../../pages/dialog/dialog.component';
import {WishlistService} from '../../../config/wishlist.service';
import {NavbarService} from '../../../config/navbar.service';
import {AuthService, FacebookLoginProvider, GoogleLoginProvider} from 'angularx-social-login';
import { Country } from '../../../models/country';
import { Settings } from '../../../models/settings';
import { environment } from 'src/environments/environment';


declare var $: any;
declare var Waypoint: any;
declare var google: any;
declare var clevertap: any;

export interface searchItem {
  id: number;
  name: string;
  product_total: string;
  type: string;
}


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  user: User;
  overlayHeight = '0px';
  sideMenuWidth = '0px';
  isSideMenuOpen = false;
  isStoreSwitcherOpen = false;
  navigationStatus = true;
  website_header_text: string;
  categories: Category[] = [];
  countries: Country[] = [];
  @Input() stores: Store[] = [];
  @Input() store: Store;
  hiddenOverlay = true;
  lang: string;
  q: string;
  isLoading = false;
  searchForm: FormGroup;
  suggestions: Suggestion[] = [];
  recentSearches: Suggestion[] = [];
  popularSearches: Suggestion[] = [];
  resize = new Subject();
  top_selected_category: number;
  top_selected_category_name: string;
  support_email = '';
  support_phone = '';
  cartCount = 0;
  wishlistCount = 0;
  loginForm: FormGroup;
  registerForm: FormGroup;
  loginSubmitted = false;
  registerSubmitted = false;
  hideModal = true;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  public currentLang: string;
  private lastScrollTop = 0;
  @ViewChild('headerContainer') headerContainer: ElementRef;
  genderOptions = [];
  categorySuggestions: searchItem[] = [];
  productSuggestions: searchItem[] = [];
  categorySuggestionTab = 0;
  productSuggestionTab = 0;
  hideSubMenu: number = 0;
  settings: Settings;
  openLinks: string = 'N';
  isBrowser: boolean;
  openLeftMenu: number = 0;
  
  constructor(
    private authenticationService: AuthenticationService,
    private configService: ConfigService,
    public configSettings: ConfigSettings,
    protected localStorage: LocalStorage,
    private translate: TranslateService,
    private cryptService: CryptService,
    private route: ActivatedRoute,
    public router: Router,
    private cartService: CartService,
    private wishlistService: WishlistService,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private userService: UserService,
    private settingsService: SettingsService,
    private dialog: MatDialog,
    private navbarService: NavbarService,
    private authService: AuthService,
    private ngZone: NgZone,
    private http: HttpClient, @Inject(PLATFORM_ID) platformId: string,
    @Inject(DOCUMENT) private document: any
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.genderOptions = [{value: 'M', label: 'Male'}, {value: 'F', label: 'Female'}];
    this.lang = this.settingsService.getLanguage();
    if (this.isBrowser) {
      this.readSupportDetails();
    }
    this.settingsService.language$.subscribe((lang) => {
      this.lang = lang;
    });
    this.wishlistService.currentWishlistCount.subscribe(data => this.wishlistCount = data);

    this.cartService.currentcartCount.subscribe(data => this.cartCount = data);

    this.readAllCategories();
    // this.getCountries();
    this.getSettings();

    this.searchForm = this.fb.group({
      q: [null, Validators.required]
    });

    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', [Validators.required]],
    });

    this.registerForm = this.fb.group({
      full_name: ['', Validators.required],
      // last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', Validators.required],
      phone: ['', [Validators.required, Validators.minLength(8)]],
      phone_code: [null],
      gender: [null],
      newsletter_subscribed: [null],
    }, {
      validator: ValidationService.matchPassword // your validation method
    });

    this.registerForm.get('phone_code').setValue('965');

    this.userService.getUserSession().then(response => {
      if (response !== false) {
        this.user = response;
      }
    });

    clevertap.notifications.push({
      "titleText":'Would you like to receive Push Notifications?',
      "bodyText":'We promise to only send you relevant content and give you updates on your transactions',
      "okButtonText":'Sign me up!',
      "rejectButtonText":'No thanks',
      "okButtonColor":'#f28046',
      "askAgainTimeInSeconds": 86400,
      "serviceWorkerPath": 'assets/js/clevertap_sw.js'
    });
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      let btnWidth = "400px";
      if ($(window).width() >= 667) {
        btnWidth = "226px";
      }
      google.accounts.id.initialize({
        client_id: environment.google_client_id,
        // ux_mode: "redirect",
        callback: (response: any) => this.handleGoogleSignIn(response)
      });
      google.accounts.id.renderButton(
        document.getElementById("btn-g"),
        { size: "large", type: "text", shape: "square", width: btnWidth}  // customization attributes
      );
      
      setTimeout(() => {
        const $searchWrapper = $('.header-search-wrapper'),
          $body = $('body'),
          $html = $('html'),
          $searchToggle = $('.search-toggle');
          //jw
          const $mobileMenu = $('.mobile-menu-container');

        $searchToggle.on('click', function (e) {
          $searchWrapper.toggleClass('show');
          $(this).toggleClass('active');
          $searchWrapper.find('input').focus();
          e.preventDefault();
        });

        $body.on('click', function (e) {
          // console.log("body clicked");
          $(".suggestions-container").hide();
          if ( $searchWrapper.hasClass('show') ) {
            $searchWrapper.removeClass('show');
            $searchToggle.removeClass('active');
            $body.removeClass('is-search-active');
          }
        });

        $('.header-search').on('click', function (e) {
          e.stopPropagation();
        });


        // Menu init with superfish plugin
        if ( $.fn.superfish ) {
          $('.menu, .menu-vertical').superfish({
            popUpSelector: 'ul, .megamenu',
            hoverClass: 'show',
            delay: 0,
            speed: 80,
            speedOut: 80 ,
            autoArrows: true
          });
        }

        // Mobile Menu Toggle - Show & Hide
        // $('.mobile-menu-toggler').on('click', function (e) {
        //   $body.toggleClass('mmenu-active');
        //   $(this).toggleClass('active');
        //   //Hide body scroll and add menu scroll for mobile
        //   $html.addClass("mobile-menu-fixed-position");
        //   $mobileMenu.addClass("scrollmenu");
        //   e.preventDefault();
        // });

        // $('.mobile-menu-overlay, .mobile-menu-close').on('click', function (e) {
        //   $body.removeClass('mmenu-active');
        //   $('.menu-toggler').removeClass('active');
        //   //Hide body scroll and add menu scroll for mobile
        //   $html.removeClass("mobile-menu-fixed-position");
        //   $mobileMenu.removeClass("scrollmenu");
        //   e.preventDefault();
        // });

        //Remove dsibale scroll on click menu
        // $('.mobile-menu li ').on('click',function () {
        //   $html.removeClass("mobile-menu-fixed-position");
        //   $mobileMenu.removeClass("scrollmenu");
        // });

        // Add Mobile menu icon arrows to items with children
        // $('.mobile-menu').find('li').each(function () {
        //   const $this = $(this);

        //   if ( $this.find('ul').length ) {
        //     $('<span/>', {
        //       'class': 'mmenu-btn'
        //     }).appendTo($this.children('a'));
        //   }
        // });

        // Mobile Menu toggle children menu
        // $('.mmenu-btn').on('click', function (e) {
        //   const $parent = $(this).closest('li'),
        //     $targetUl = $parent.find('ul').eq(0);

        //   if ( !$parent.hasClass('open') ) {
        //     $targetUl.slideDown(300, function () {
        //       $parent.addClass('open');
        //     });
        //   } else {
        //     $targetUl.slideUp(300, function () {
        //       $parent.removeClass('open');
        //     });
        //   }

        //   e.stopPropagation();
        //   e.preventDefault();
        // });
        
      }, 4000);
      this.ngZone.runOutsideAngular(() => {
        if ( $('.sticky-header').length && $(window).width() >= 992 ) {
          if ( $.fn.waypoint ) {
            var sticky = new Waypoint.Sticky({
              element: $('.sticky-header')[0],
              stuckClass: 'fixed',
              offset: -300,
                    handler: function ( direction ) {
                        // Show category dropdown
                        // if ( catInitVal &&  direction == 'up') {
                        //     catDropdown.addClass('show').find('.dropdown-menu').addClass('show');
                        //     catDropdown.find('.dropdown-toggle').attr('aria-expanded', 'true');
                        //     return false;
                        // }
        
                        // // Hide category dropdown on fixed header
                        // if ( catDropdown.hasClass('show') ) {
                        //     catDropdown.removeClass('show').find('.dropdown-menu').removeClass('show');
                        //     catDropdown.find('.dropdown-toggle').attr('aria-expanded', 'false');
                        // } 
                    }
            });
          }
        }
      });
    }
    

    // this.navbarService.scrollChange.subscribe(scrollTop => {
    //   if(scrollTop < this.lastScrollTop && scrollTop < 60) {
    //     // this.headerContainer.nativeElement.classList.remove("scroll-down");
    //     // this.headerContainer.nativeElement.classList.add("scroll-up");
    //     // console.log(this.headerContainer.nativeElement.children[0]);
    //     this.headerContainer.nativeElement.children[1].classList.remove('fixed');
    //   }
    //   else {
    //     // this.headerContainer.nativeElement.classList.remove("scroll-up");
    //     // this.headerContainer.nativeElement.classList.add("scroll-down");
    //     this.headerContainer.nativeElement.children[1].classList.add('fixed');
    //   }

    //   this.lastScrollTop = (scrollTop <= 0) ? 0 : scrollTop;
    // });
  }

  handleGoogleSignIn(response: any) {
    // console.log(response);
    // This next is for decoding the idToken to an object if you want to see the details.
    let base64Url = response.credential.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    console.log(JSON.parse(jsonPayload));
    let userData = JSON.parse(jsonPayload);

    this.configSettings.toggleLoading(true);
    if (!userData.email) {
      this.toastrService.error(this.translate.instant('You have problem in your google account. Please try again later.'));
      this.configSettings.toggleLoading(false);
      return false;
    }
    const device = this.configSettings.getBrowserVersion();
    const param = {
      first_name: userData.given_name,
      last_name: userData.family_name,
      email: userData.email,
      social_register_type: 'G',
      device_type: 'W',
      device_model: device,
      app_version: device,
      device_token: '',
      os_version: window.navigator.platform,
      lang: this.lang,
      store: this.store.iso_code
    };

    this.authenticationService.socialRegister(param)
      .pipe(first())
      .subscribe(
        response => {
          let status = '';
          if (response.status === 200) {
            status = 'Success';
            this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
              this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                this.cartService.uploadOfflineCart(this.lang, this.store.iso_code, response.data.id).then(() => {
                  this.cartService.clearStorage('cart').then(() => {
                    window.location.href = window.location.href;
                  });
                  // this.redirectTo();
                });
              });
            });
            this.cleverTapLogin(response.data);
          } else {
            status = 'Failed';
            this.toastrService.error(response.message);
            this.configSettings.toggleLoading(false);
          }
          this.clevertapEvent("Login", {"Method Used": "Google", "Status": status});
        }, error => {
          this.configSettings.toggleLoading(false);
        }
      );
  }

  toggleNav() {
    this.toggleLeftMenu();
  }

  toggleLeftMenu() {
    if (this.openLeftMenu == 0) {
      this.openLeftMenu = 1;
      this.document.body.classList.add('mmenu-active');
      $('html').addClass('mobile-menu-fixed-position');
    } else {
      this.openLeftMenu = 0;
      this.document.body.classList.remove('mmenu-active');
      $('html').removeClass('mobile-menu-fixed-position');
    }
  }

  toggleSubMenu(event: Event) {
    let ele = event.currentTarget;
    let parent = $(ele).parent().parent('li'), targetUl = parent.children('ul').eq(0);

    if (!parent.hasClass('open')) {
      targetUl.slideDown(300, function () {
        parent.addClass('open');
      });
    } else {
      targetUl.slideUp(300, function () {
        parent.removeClass('open');
      });
    }
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  readSupportDetails() {
    this.localStorage.getItem('support_details').subscribe((data: any) => {
      if (data) {
        this.support_email = data.support_email;
        this.support_phone = data.support_phone;
      } else {
        this.navbarService.currentSupportDetails.subscribe((observerData: any) => {
          if (observerData) {
            this.support_email = observerData.support_email;
            this.support_phone = observerData.support_phone;
          }
        });
      }
    });
    this.settingsService.settings.subscribe(data => this.settings = data);
  }

  setLanguage() {
    const language = (this.lang === 'en') ? 'ar' : 'en';
    this.settingsService.setLanguage(language);
    clevertap.profile.push({
      "Site": {
        "Language": language,
      }
     });
    window.location.href = window.location.href;
  }

  changeStore(event: any, id: number) {
    const index = this.stores.findIndex(store => store.id === id);
    const selectedStore = this.stores[index];
    this.setLocalStore(selectedStore);
  }

  setLocalStore(selectedStore: any) {
    this.localStorage.setItem('store_object', selectedStore).subscribe(() => {
      // window.location.reload();
      window.location.href = window.location.href;
    });
  }

  readAllCategories() {
    const params = {
      lang: this.lang
    };
    this.configService.readRequest('all-categories-website', params)
      .subscribe(response => {
        if (response.body.data) {
          this.categories = <Category[]>response.body.data;
          this.configSettings.setCategories(this.categories);
          // console.log('Header categories', this.categories);

          this.categories.map(category => {
            if (+category.id === +this.top_selected_category) {
              this.top_selected_category_name = category.name;
            }
          });

          if (typeof this.top_selected_category === 'undefined') {
            this.top_selected_category = this.categories[0].id;
            this.top_selected_category_name = this.categories[0].name;
          }
        }
      });
  }

  getCountries() {
    const params = {
      lang: this.lang
    };
    this.configService.readRequest('country', params)
      .subscribe(response => {
        if (response.body.data) {
          this.countries = <Country[]>response.body.data;
        }
      });
  }

  onSearchSubmit(leftMenuSearch) {
    Object.keys(this.searchForm.controls).forEach(field => {
      const control = this.searchForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    if (this.searchForm.dirty && this.searchForm.valid) {
      // const _form = this.searchForm.value;
      const q = this.searchForm.controls['q'].value;
      this.searchForm.reset();
      this.router.navigate(['/search'], {queryParams: {q: q}});
      this.categorySuggestions = [];
      this.productSuggestions = [];
      if (leftMenuSearch === 1) {
        this.toggleNav();
        $('html').removeClass("mobile-menu-fixed-position");
        $('html').removeClass("scrollmenu");
      }
      // window.location.href = '/search?q=' + this.q;
    }
  }

  onLoginSubmit() {
    this.loginSubmitted = true;
    Object.keys(this.loginForm.controls).forEach(field => {
      const control = this.loginForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    if (this.loginForm.dirty && this.loginForm.valid) {
      const _form = this.loginForm.value;
      this.doLogin(_form);
    }
  }

  doLogin(formData: any) {
    this.configSettings.toggleLoading(true);
    try {
      this.authenticationService.login(formData.email, formData.password, this.lang, this.store.iso_code)
        .pipe(first())
        .subscribe(
          response => {
            let status = '';
            if (response.status === 200) {
              status = 'Success';
              this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                  // this.toastrService.success(this.translate.instant('Logged in'));
                  this.cartService.uploadOfflineCart(this.lang, this.store.iso_code, response.data.id).then(() => {
                    this.cartService.clearStorage('cart').then(() => {
                      window.location.href = window.location.href;
                    });
                    // this.redirectTo();
                  });
                });
              });

              this.cleverTapLogin(response.data);
            } else {
              status = 'Failed';
              this.toastrService.error(response.message);
              this.configSettings.toggleLoading(false);
            }
            this.clevertapEvent("Login", {"Method Used": "email", "Status": status});
          }, error => {
            this.configSettings.toggleLoading(false);
          }
        );
    } catch (e) {
      console.log(e);
      this.configSettings.toggleLoading(false);
    }
  }

  onRegisterSubmit() {
    this.registerSubmitted = true;
    Object.keys(this.registerForm.controls).forEach(field => {
      const control = this.registerForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    if (this.registerForm.dirty && this.registerForm.valid) {
      const _form = this.registerForm.value;
      this.doRegister(_form);
    }
  }

  doRegister(formData: any) {
    this.configSettings.toggleLoading(true);
    try {
      formData.phone_code = '+'+formData.phone_code;
      formData.newsletter_subscribed = (formData.newsletter_subscribed === true) ? 1 : 0;
      formData.lang = this.lang;
      formData.store = this.store.iso_code;
      this.authenticationService.register(formData)
        .pipe(first())
        .subscribe(
          response => {
            let status = '';
            if (response.status === 200) {
              status = 'Success';
              this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                  // this.toastrService.success(this.translate.instant('Successfully Registered.'));
                  this.cartService.uploadOfflineCart(this.lang, this.store.iso_code, response.data.id).then(() => {
                    this.cartService.clearStorage('cart').then(() => {
                      $('#signin-modal .icon-close').trigger('click');
                      this.registerForm.reset();
                      window.location.href = window.location.href;
                    });
                    // this.redirectTo();
                  });
                });
              });

              this.cleverTapLogin(response.data);
            } else {
              status = 'Failed';
              this.toastrService.error(response.message);
              this.configSettings.toggleLoading(false);
            }
            this.clevertapEvent("Sign Up", {"Register Option": "Website", "Status": status});
          }, error => {
            this.configSettings.toggleLoading(false);
          }
        );
    } catch (e) {
      console.log(e);
      this.configSettings.toggleLoading(false);
    }
  }

  showLogoutModal() {
    this.dialog.open(DialogComponent, {
      data: {logout: true, user: this.user},
    });
  }

  /**
   * Sign in using facebook
   */
  signInWithFB(): void {
    this.configSettings.toggleLoading(true);
    try {
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
        (userData) => {
          if (!userData.email) {
            this.toastrService.error(this.translate.instant('You have problem in your facebook account. Please try again later.'));
            this.configSettings.toggleLoading(false);
            return false;
          }
          const device = this.configSettings.getBrowserVersion();
          const param = {
            facebook_id: userData.id,
            first_name: userData.firstName,
            last_name: userData.lastName,
            email: userData.email,
            social_register_type: 'F',
            device_type: 'W',
            device_model: device,
            app_version: device,
            device_token: '',
            os_version: window.navigator.platform,
            lang: this.lang,
            store: this.store.iso_code
          };

          this.authenticationService.socialRegister(param)
            .pipe(first())
            .subscribe(
              response => {
                let status = '';
                if (response.status === 200) {
                  status = "Success";
                  this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                    this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                      this.cartService.uploadOfflineCart(this.lang, this.store.iso_code, response.data.id).then(() => {
                        this.cartService.clearStorage('cart').then(() => {
                          window.location.href = window.location.href;
                        });
                        // this.redirectTo();
                      });
                    });
                  });
                  this.cleverTapLogin(response.data);
                } else {
                  status = "Failed";
                  this.toastrService.error(response.message);
                  this.configSettings.toggleLoading(false);
                }
                this.clevertapEvent("Login", {"Method Used": "Facebook", "Status": status});
              }, error => {
                this.configSettings.toggleLoading(false);
              }
            );
        }, error => {
          this.configSettings.toggleLoading(false);
        }
      );
    } catch (e) {
      console.log(e);
      this.configSettings.toggleLoading(false);
    }
  }

  /**
   * Sign in using google
   */
  signInWithGoogle(): void {
    // this.configSettings.toggleLoading(true);
    return;
    try {
      console.log('g login');
      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
        (userData) => {
          if (!userData.email) {
            this.toastrService.error(this.translate.instant('You have problem in your google account. Please try again later.'));
            this.configSettings.toggleLoading(false);
            return false;
          }
          const device = this.configSettings.getBrowserVersion();
          const param = {
            first_name: userData.firstName,
            last_name: userData.lastName,
            email: userData.email,
            social_register_type: 'G',
            device_type: 'W',
            device_model: device,
            app_version: device,
            device_token: '',
            os_version: window.navigator.platform
          };

          this.authenticationService.socialRegister(param)
            .pipe(first())
            .subscribe(
              response => {
                if (response.status === 200) {
                  this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                    this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                      this.cartService.uploadOfflineCart(this.lang, this.store.iso_code, response.data.id).then(() => {
                        this.cartService.clearStorage('cart').then(() => {
                          window.location.href = window.location.href;
                        });
                        // this.redirectTo();
                      });
                    });
                  });
                } else {
                  this.toastrService.error(response.message);
                  this.configSettings.toggleLoading(false);
                }
              }, error => {
                this.configSettings.toggleLoading(false);
              }
            );
        }, error => {
          this.configSettings.toggleLoading(false);
        }
      );
    } catch (e) {
      console.log(e);
      this.configSettings.toggleLoading(false);
    }
  }

  openForgotPassword() {
    $('#signin-modal').modal('hide');
    this.router.navigate(['/forgot-password']);
    return false;
  }

  navigateToWishlist() {
    if (this.user) {
      this.router.navigate(['/wishlist']);
    } else {
      $('#signin-modal').modal('show');
    }
    return false;
  }
  navigateToBundle() {
    if (this.user) {
      this.router.navigate(['/bundles']);
    } else {
      $('#signin-modal').modal('show');
    }
    return false;
  }

  onOptionSelect(phone_code, event) {

  }

  getSettings() {
    const params = {
      lang: this.lang
    };
    this.configService.readRequest('settings', params)
    .subscribe(response => {
      let data = response.body.data;
      if (data) {
        this.countries = <Country[]>data.countries;
        this.settingsService.setSettings(data.settings);
      }
    });
  }

  searchSuggestions() {
    const params = {
      lang: this.lang
    };
    this.configService.readRequest('settings', params)
    .subscribe(response => {
      let data = response.body.data;
      if (data) {
        this.countries = <Country[]>data.countries;
        this.settingsService.setSettings(data.settings);
      }
    });
  }

  getSearch(query) {
    if (query.length > 3) {
        const params = {
            lang: this.lang,
            q: query
        };
        this.configService.readRequest('suggestions', params)
        .subscribe(response => {
          let data = response.body.data;
          // console.log('search', data);
          if (data) {
            $(".suggestions-container").show();
            data.map(item => {
              if (item.type == 'C') {
                this.categorySuggestionTab = 1;
                this.categorySuggestions.push(item);
              }
              if (item.type == 'P') {
                this.productSuggestionTab = 1;
                this.productSuggestions.push(item);
              }
            });
            this.clevertapEvent("Searched", {
              "Keyword": query
            });
          }
        });
    } else {
      this.categorySuggestions = [];
      this.productSuggestions = [];
    }
  }

  switch(tab) {
    if (tab == 'P') {
      this.productSuggestionTab = 1;
      this.categorySuggestionTab = 0;
    }
    if (tab == 'C') {
      this.categorySuggestionTab = 1;
      this.productSuggestionTab = 0;
    }
  }

  hideSuggestions() {
    this.categorySuggestions = [];
    this.productSuggestions = [];
  }

  setHideSubMenu(hide: number) {
    this.hideSubMenu = hide;
  }


  getCategorySlug(item: Category) {
    if (item.slug != '') {
      return item.slug;
    } else {
      return this.configSettings.cleanUrl(item.name);
    }
  }

  openMenu() {
    if (this.openLinks == 'Y') {
      this.openLinks = 'N';
    } else {
      this.openLinks = 'Y';
    }
  }

  clevertapEvent(title, params) {
    clevertap.event.push(title, params);
  }

  cleverTapLogin(params) {
    if (params != undefined) {
      let userInfo = params;
      let phoneCode = userInfo.phone_code;
      if (userInfo.phone_code == '') {
        phoneCode = '+965';
      }
      clevertap.onUserLogin.push({
        "Site": {
          "Name": userInfo.first_name,            // String
          "Identity": userInfo.id,              // String or number
          "Email": userInfo.email,         // Email address of the user
          "Phone": phoneCode+userInfo.phone,           // Phone (with the country code)
          "Gender": userInfo.gender,
          "Language": this.lang,                     
          // optional fields. controls whether the user will be sent email, push etc.
          "MSG-email": true,                // Disable email notifications
          "MSG-push": true,                  // Enable push notifications
          "MSG-sms": true,                   // Enable sms notifications
          "MSG-whatsapp": true,              // Enable WhatsApp notifications
        }
      });
    }
  }

}
