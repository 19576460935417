import {EventEmitter, Injectable, Output, Directive} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Address} from '../models/address';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  navigationStatus = true;
  @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();

  website_header_text = '';
  @Output() getWebsiteHeaderText: EventEmitter<string> = new EventEmitter<string>();

  private support_details = new Subject<any>();
  currentSupportDetails = this.support_details.asObservable();

  @Output() scrollChange: EventEmitter<any> = new EventEmitter<any>();
  
  emitScrollEvent: boolean = true
  
  constructor(
    protected localStorage: LocalStorage,
  ) {
  }

  toggleMainNavigation(key: boolean) {
    this.navigationStatus = key;
    this.change.emit(this.navigationStatus);
  }

  setWebsiteHeaderText(key: string) {
    this.website_header_text = key;
    this.getWebsiteHeaderText.emit(this.website_header_text);
  }

  setSupportDetails(data: any) {
    this.support_details.next(data);
    this.localStorage.setItem('support_details', data).subscribe(() => {
    });
  }
}
