import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
// import { HeaderUserActionsComponent } from './header-user-actions/header-user-actions.component';
import {CRYPT_CONFIG_PROVIDER, CryptConfigProvider, EncryptionServiceModule} from 'angular-encryption-service';
import {ConfigSettings} from './config/config.settings';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPopper} from 'angular-popper';
import {ClickOutsideModule} from 'ng-click-outside';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SlickModule} from 'ngx-slick';
import {NgxPaginationModule} from 'ngx-pagination';
import {StorageModule} from '@ngx-pwa/local-storage';
import {NguiInViewComponent} from './ngui-in-view.component';
// import {GtagModule} from 'angular-gtag';
import { GoogleTagManagerService } from "angular-google-tag-manager";
import {HeaderModule} from './layouts/common/header/header.module';
import {FooterModule} from './layouts/common/footer/footer.module';
import {AuthInterceptor} from './interceptors/auth-interceptor';
import {SettingsService} from './services/settings-service';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {ToastrModule} from 'ngx-toastr';
import {LoadingComponent} from './pages/loading/loading.component';
import { StoreDialogModule } from './pages/store-dialog/store-dialog.module';
import { MatTooltipModule } from '@angular/material/tooltip';
const AppCryptConfigProvider: CryptConfigProvider = {
  getSalt(): Promise<string> {
    return Promise.resolve(ConfigSettings.ENCRYPT_KEY);
  }
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import {UrlSerializer, UrlTree, DefaultUrlSerializer} from '@angular/router';

export class CustomeUrlSerializer implements UrlSerializer {
    parse(url: any): UrlTree {
        let dus = new DefaultUrlSerializer();
        return dus.parse(url);
    }
    serialize(tree: UrlTree): any {
        let dus = new DefaultUrlSerializer(),
            path = dus.serialize(tree);
        let pathArr = path.split('?');
        let res = pathArr[0].charAt(pathArr[0].length - 1);
        if (res != '/') {
          pathArr[0] += "/";
        }
        let joinSeparator = pathArr.length > 1 ? "?" : "";
        path = pathArr.join(joinSeparator);
        return path;
    }
}


@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    PageNotFoundComponent,
    NguiInViewComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    EncryptionServiceModule.forRoot(),
    NgSelectModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatTabsModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatInputModule,
    ToastrModule.forRoot(),
    HeaderModule,
    FooterModule,
    MatDialogModule,
    MatTooltipModule,
    // BrowserAnimationsModule,
    // NoopAnimationsModule,
    NgxPopper,
    ClickOutsideModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // SocialLoginModule,
    SlickModule.forRoot(),
    NgxPaginationModule,
    StoreDialogModule,
    StorageModule.forRoot({IDBNoWrap: false}),
    // GtagModule.forRoot({trackingId: 'UA-151209487-1', trackPageviews: true}),
    
  ],
  providers: [
    SettingsService,
    {provide: CRYPT_CONFIG_PROVIDER, useValue: AppCryptConfigProvider},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: "googleTagManagerId", useValue: "" },
    GoogleTagManagerService,
    { provide: UrlSerializer, useClass: CustomeUrlSerializer }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

