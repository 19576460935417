<div class="app-loading" *ngIf="loading">
    <div id="loader" style="position: fixed; width: 170px; height: 170px; justify-content: center;left: 50%; margin-left: -100px;  top: 50%; margin-top: -100px;z-index: 99999;">
        <img src="assets/images/loading_icon.gif">
    </div>
</div>

<style>
#stringPreloader {
    -webkit-animation: spinner 2.5s infinite linear;
    animation: spinner 3.5s infinite linear
}
@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}
</style>


