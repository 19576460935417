import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import CryptoJS from 'crypto-js';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const key = this.getHeaderKey();
    const authReq = req.clone({
      headers: req.headers
        .set('Authorization', `Bearer ${key}`)
        // .set('Access-Control-Allow-Origin', '*')
    });
    return next.handle(authReq) as any;
  }

  getHeaderKey() {
    const dt = new Date();
    const timeStampNumber = Math.floor(new Date().getTime() / 1000);
    // const timeStampNumber = Math.floor(new Date(new Date().toLocaleString('en-US', {timeZone: 'Asia/Kuwait'})).getTime() / 1000);
    const timeStamp = this.bin2hex(timeStampNumber);
    const authToken = environment.authToken;
    const deviceType = this.bin2hex('web');
    const key = timeStamp + '|||' + authToken + '|||' + deviceType;
    return this.aesEncrypt(key);
  }

  aesEncrypt(data) {
    // DEV KEYS
    const iv = environment.authIv;
    const key = environment.authKey;
    const cipher = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });
    return cipher.toString();
  }

  bin2hex(s) {
    let f = 0;
    const a = [];
    s += '';
    f = s.length;
    for (let i = 0; i < f; i++) {
      a[i] = s.charCodeAt(i).toString(16).replace(/^([\da-f])$/, '0$1');
    }
    return a.join('');
  }
}
