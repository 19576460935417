<img src="{{data.value}}" *ngIf="data.image" class="img-fluid"/>
<div class="row justify-content-center mh-100 position-relative" *ngIf="data.images">
    <div class="col-1 thumb">
        <div *ngFor="let image of data.images;let i = index;">
            <img src="{{image}}" class="img-fluid">
        </div>
    </div>
    <div class="col-12 col-md-4 slider-gallery slick-gallery float-right">
        <img *ngFor="let image of data.images;let i = index;" src="{{image}}" class="">
    </div>
    <div class="modal-close" (click)="closeModal()">x</div>
</div>
<div class="" *ngIf="data.cancelOrder" id="cancel-order-dialog">
    <h4 class="font-medium font-bold text-center mb-3">{{'CANCELLATION REASON' | translate}}</h4>
    <span class="d-block font-regular font-small mb-1 custom-radio">
        <input type="radio" [(ngModel)]="reaon" value="Wrong address" id="radio_1">
        <label for="radio_1"><i class="radio-icon"></i> {{'Wrong address' | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-2 custom-radio">
        <input type="radio" [(ngModel)]="reaon" value="Wrong quantity" id="radio_2">
        <label for="radio_2"><i class="radio-icon"></i> {{'Wrong quantity' | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-2 custom-radio">
        <input type="radio" [(ngModel)]="reaon" value="Payment method is not suitable for me" id="radio_3">
        <label for="radio_3"><i class="radio-icon"></i> {{'Payment method is not suitable for me' | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-2 custom-radio">
        <input type="radio" [(ngModel)]="reaon" value="I don't want this item anymore" id="radio_4">
        <label for="radio_4"><i class="radio-icon"></i> {{"I don't want this item anymore" | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-4 custom-radio">
        <input type="radio" [(ngModel)]="reaon" value="Other reasons" id="radio_5">
        <label for="radio_5"><i class="radio-icon"></i> {{'Other reasons' | translate}}</label>
    </span>
    <div class="row m-0">
        <div class="col-sm-12 col-md-6 col-lg-6 p-0 pr-1">
            <button type="button" class="custom-button btn btn-dark btn-block"
                    (click)="closeDialog()">{{'Cancel' | translate}}</button>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 p-0 pr-1 mobile-view-dialog-box">
            <button type="button" id="btn_order_ok" class="custom-button btn btn-dark btn-block"
                    [disabled]="(!reaon || disableCancelSubmit)" [ngClass]="{'disabled': (!reaon)}"
                    (click)="cancelOrder()">{{'OK' | translate}}</button>
        </div>
    </div>
</div>

<div class="" *ngIf="data.logout" id="logout-dialog">
    <h5 class="text-center mb-3">{{'Hello' | translate}} {{user.first_name}}</h5>
    <p class="mb-2">{{'Are you sure you want to logout?' | translate}}</p>
    <div class="row m-0">
        <div class="col-sm-6 col-md-6 col-lg-6 p-0 pr-1">
            <button type="button" class="custom-button btn btn-primary btn-block"
                    (click)="closeDialog()">{{'Cancel' | translate}}</button>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 p-0 pr-1 mobile-view-dialog-box">
            <button type="button" class="custom-button btn btn-light btn-block"
                    (click)="logout()">{{'OK' | translate}}</button>
        </div>
    </div>
</div>

<div class="" *ngIf="data.confirmDialog" id="remove-cart-confirm-dialog">
    <h5 class="text-center mb-3">{{'Confirm' | translate}}</h5>
    <p class="mb-2">{{confirmMessage}}</p>
    <div class="row m-0">
        <div class="col-sm-6 col-md-6 col-lg-6 p-0 pr-1">
            <button type="button" class="custom-button btn btn-primary btn-block"
                    (click)="dialogRef.close(false)">{{'Cancel' | translate}}</button>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 p-0 pr-1 mobile-view-dialog-box">
            <button type="button" class="custom-button btn btn-light btn-block"
                    (click)="dialogRef.close(true)">{{'OK' | translate}}</button>
        </div>
    </div>
</div>


<div class="{{lang == 'ar' ? 'text-right' : ''}}" *ngIf="data.returnReason" id="return-reason-dialog">
    <h4 class="font-medium font-bold text-center mb-3">{{'Return Details' | translate}}</h4>

    <h5 class="font-medium font-regular mb-2">{{'Select the quantity:' | translate}}</h5>
    <ng-select [items]="quantityOptions" [placeholder]="'Select quantity' | translate" [addTag]="true" bindLabel="label"
               bindValue="value" [clearable]="false" [searchable]=false [(ngModel)]="returnQuantity"></ng-select>

    <h5 class="font-medium font-regular mt-3 mb-2">{{'Select a reason to return:' | translate}}</h5>
    <span class="d-block font-regular font-small mb-1 custom-radio">
        <input type="radio" name="request-reason" [(ngModel)]="requestReason" value="Damaged Goods" id="rradio_1">
        <label for="rradio_1"><i class="radio-icon"></i> {{'Damaged Goods' | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-2 custom-radio">
        <input type="radio" name="request-reason" [(ngModel)]="requestReason" value="Color is faded" id="rradio_2">
        <label for="rradio_2"><i class="radio-icon"></i> {{'Color is faded' | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-2 custom-radio">
        <input type="radio" name="request-reason" [(ngModel)]="requestReason" value="Size is not matching"
               id="rradio_3">
        <label for="rradio_3"><i class="radio-icon"></i> {{'Size is not matching' | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-2 custom-radio">
        <input type="radio" name="request-reason" [(ngModel)]="requestReason" value="Order mismatch" id="rradio_4">
        <label for="rradio_4"><i class="radio-icon"></i> {{"Order mismatch" | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-2 custom-radio">
        <input type="radio" name="request-reason" value="Other reason" id="rradio_5">
        <label for="rradio_5"><i class="radio-icon"></i> {{'Other reason' | translate}}</label>
    </span>
    <div class="mb-4">
        <textarea rows="4" placeholder="{{'Reason for return*' | translate}}" [(ngModel)]="requestReasonText"
                  name="request-reason"></textarea>
    </div>
    <div class="row m-0">
        <div class="col-12 p-0">
            <button type="button" id="btn_reason_ok" class="custom-button btn btn-dark btn-block"
                    [ngClass]="{'disabled': (!requestReason && !requestReasonText)}"
                    (click)="submitReturnReason()">{{'OK' | translate}}</button>
        </div>
    </div>
</div>

<div class="" *ngIf="data.notifyme" id="notify-me-dialog">
    <h5 class="text-center mb-3">{{'Registered for Notification!' | translate}}</h5>
    <p class="mb-2 text-center">{{'We will send a notification to you when product will come back.' | translate}}</p>
    <div class="row m-0 justify-content-center">
        <div class="col-6 p-0 pr-1">
            <button type="button" class="custom-button btn btn-primary btn-block"
                    (click)="closeModal()">{{'Continue Shopping' | translate}}</button>
        </div>
    </div>
</div>

<div class="" *ngIf="data.errorDisplay" id="error-dialog">
    <h5 class="text-center mb-3">{{'Error Message!' | translate}}</h5>
    <p class="mb-2 text-center">{{'Please select attribute dropdown value.' | translate}}</p>
    <div class="row m-0">
        <div class="col-12 p-0">
            <button type="button" class="custom-button btn btn-primary btn-block"
            (click)="closeModal()">{{'OK' | translate}}</button>
        </div>
    </div>
    
</div>