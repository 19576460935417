import {NgModule} from '@angular/core';
import {HeaderComponent} from './header.component';
import {SharedModule} from '../../../shared/shared.module';
// import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DialogModule} from '../../../pages/dialog/dialog.module';
import {AuthService, AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule} from 'angularx-social-login';
import {NavbarService} from '../../../config/navbar.service';
import {NgSelectModule} from '@ng-select/ng-select';

const config = new AuthServiceConfig([
  // {
  //   id: GoogleLoginProvider.PROVIDER_ID,
  //   provider: new GoogleLoginProvider('410248077111-q52enhqfim8gltqg50f175df6h3522l5.apps.googleusercontent.com')
  // },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('2054877358179359')
  },
]);

export function provideConfig() {
  return config;
}


@NgModule({
  declarations: [HeaderComponent],
  imports: [
    SharedModule,
    DialogModule,
    NgSelectModule,
    SocialLoginModule
  ],
  exports: [
    HeaderComponent
  ],
  providers: [
    AuthService,
    NavbarService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ]

})
export class HeaderModule {
}
