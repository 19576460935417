import { NgModule } from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {StoreDialogComponent} from './store-dialog.component';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';



@NgModule({
  declarations: [StoreDialogComponent],
  imports: [
    SharedModule,
    MatDialogModule
  ],
  entryComponents: [
    StoreDialogComponent
  ],
  exports: [
    StoreDialogComponent
  ],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
  ],
})
export class StoreDialogModule { }
