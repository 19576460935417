<header class="header header-7" #headerContainer>
    <div class="header-top" style="background-color: #1a1919;color: #fff;"> 
        <div class="container-fluid ">
            <div class="header-left">
                <div class="header-dropdown" *ngIf="store">
                    <a href="javascript:;"><img src="{{store['flag']}}" border="0" width="20" class="mx-2"/> ({{store['currency_'+lang]}})</a>
                    <div class="header-menu black-text-c">
                        <ul>
                            <li *ngFor="let storeOption of stores">
                                <a href="javascript:;" *ngIf="storeOption.id !== store.id" (click)="changeStore($event, storeOption.id)"><img src="{{storeOption['flag']}}" border="0" width="20" class="mx-2"/> ({{storeOption['currency_'+lang]}})</a>
                            </li>
                        </ul>
                    </div><!-- End .header-menu -->
                </div><!-- End .header-dropdown -->

                <div class="header-dropdown">
                    <a (click)="setLanguage()" class="language-change-link" href="javascript:;">{{(lang === 'en') ? 'العربية' : 'English'}}</a>
                </div><!-- End .header-dropdown -->
            </div><!-- End .header-left -->

            <div class="header-center d-none">
                Use Code <strong class="ml-1 mr-1 primary-color">"WEL10"</strong> to Avail 10% Off on 1st Purchase!
            </div>

            <div class="header-right">
                <!-- <div class="social-icons social-icons-color">
                    <a href="https://www.facebook.com/" class="social-icon social-facebook" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                    <a href="https://twitter.com/" class="social-icon social-twitter" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                    <a href="https://www.instagram.com/" class="social-icon social-instagram" title="Pinterest" target="_blank"><i class="icon-instagram"></i></a> -->
                    <!--<a href="javascript:;" class="social-icon social-pinterest" title="Instagram" target="_blank"><i class="icon-pinterest-p"></i></a>-->
                <!-- </div> -->
                <!-- End .soial-icons -->
                <ul class="top-menu" (click)="openMenu()" [ngClass]="{'active': (openLinks == 'Y')}">
                    <li>
                        <a href="javascript:;">Links</a>
                        <ul>
                            <!-- <li><a href="tel:#"><i class="icon-phone"></i>{{'Call:' | translate}} {{support_phone}}</a></li> -->
                            <li><a href="javascript:;" (click)="navigateToBundle()" class="my-bundle" style="display: none;">
                                <i class="bundle-icon"></i>
                                {{'My Bundles' | translate}}
                            </a></li>
                            <li><a href="javascript:;" (click)="navigateToWishlist()" class="">
                                <i class="icon-star-o"></i>
                                {{'My Wishlist' | translate}}
                                <span>({{wishlistCount}})</span>
                            </a></li>
                            <!-- <li><a href="#">{{'About Us' | translate}}</a></li> -->
                            <!-- <li><a href="#">{{'Contact Us' | translate}}</a></li> -->
                            <li>
                                <ng-container *ngIf="user; else login;">
                                    <div class="header-dropdown">
                                        <a href="javascript:;">{{'Account' | translate}}</a>
                                        <div class="header-menu black-text-c">
                                            <ul>
                                                <li>
                                                    <a href="javascript:;" [routerLink]="'/dashboard'">{{'Dashboard' | translate}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" (click)="showLogoutModal()">{{'Logout' | translate}}</a>
                                                </li>
                                            </ul>
                                        </div><!-- End .header-menu -->
                                    </div>
                                </ng-container>
                                <ng-template #login>
                                    <a href="#signin-modal" data-toggle="modal"><i class="icon-user"></i>{{'Login' | translate}}</a>
                                </ng-template>
                            </li>
                        </ul>
                    </li>
                </ul><!-- End .top-menu -->

            </div><!-- End .header-right -->
        </div>
    </div>

    <div class="header-middle sticky-header py-2" id="header-middle">
        <div class="container">
            <div class="header-left">
                <button class="mobile-menu-toggler" (click)="toggleLeftMenu()" [ngClass]="{'active': (openLeftMenu == 1)}">
                    <span class="sr-only">Toggle mobile menu</span>
                    <i class="icon-bars"></i>
                </button>

                <a [routerLink]="'/'" class="logo">
                    <img src="assets/images/smokeandsode_logo.png" alt="Smoke and Soda" width="" height="">
                </a>
            </div>
            <!-- End .header-left -->
            <nav class="main-nav">
                <ul class="menu sf-arrows">
                    <li class="">
                        <a [routerLink]="'/'" class="sf-without-ul" title="{{'Home' | translate}}">{{'Home' | translate}}</a>
                    </li>
                   
                    <li *ngFor="let category of categories;let i = index;">

                        <a [routerLink]="'/category/' + getCategorySlug(category) + '/' + category.id" (click)="setHideSubMenu(1)" (mouseover)="setHideSubMenu(0)" [ngClass]="{'sf-with-ul': (category.has_subcategory == 'Yes')}" (click)="setHideSubMenu(1)" (mouseover)="setHideSubMenu(0)" title="{{category.name}}" *ngIf="category.id != 0">
                            {{category.name}}
                        </a>
                        <a [routerLink]="'/category/' + getCategorySlug(category)" (click)="setHideSubMenu(1)" (mouseover)="setHideSubMenu(0)" [ngClass]="{'sf-with-ul': (category.has_subcategory == 'Yes')}" (click)="setHideSubMenu(1)" (mouseover)="setHideSubMenu(0)" title="{{category.name}}" *ngIf="category.slug == 'sale'">
                            {{category.name}}
                        </a>
                        <a [routerLink]="'/' + getCategorySlug(category)" class="sf-without-ul" (click)="setHideSubMenu(1)" (mouseover)="setHideSubMenu(0)" (click)="setHideSubMenu(1)" (mouseover)="setHideSubMenu(0)" title="{{category.name}}" *ngIf="category.slug == 'brands'">
                            {{category.name}}
                        </a>
                        <ul class="mb-2" *ngIf="hideSubMenu == 0 && category.has_subcategory == 'Yes'">
                            <li *ngFor="let categoryLvl2 of category.subcategories">
                                <a [routerLink]="'/category/' + getCategorySlug(category) + '/' + getCategorySlug(categoryLvl2) + '/' + categoryLvl2.id" [ngClass]="{'sf-with-ul': (categoryLvl2.has_subcategory == 'Yes')}" (click)="setHideSubMenu(1)" (mouseover)="setHideSubMenu(0)" title="{{categoryLvl2.name}}">
                                    {{categoryLvl2.name}}
                                </a>
                                <ul *ngIf="categoryLvl2.has_subcategory == 'Yes'">
                                    <li *ngFor="let categoryLvl3 of categoryLvl2.subcategories">
                                        <a [routerLink]="'/category/' + getCategorySlug(category) + '/' + getCategorySlug(categoryLvl2) + '/' + getCategorySlug(categoryLvl3) + '/' + categoryLvl3.id" (click)="setHideSubMenu(1)" (mouseover)="setHideSubMenu(0)" title="{{categoryLvl3.name}}">
                                            {{categoryLvl3.name}}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <!-- <div class="megamenu megamenu-md" *ngIf="hideSubMenu == 0">
                            <div class="row no-gutters">
                                <div class="col-md-12">
                                    <div class="menu-col">
                                        <div class="row">
                                            <div class="col-md-2" *ngFor="let categoryLvl2 of category.subcategories">
                                                <div class="menu-title">
                                                    <a [routerLink]="'/product/category/' + configSettings.cleanUrl(category.name) + '/' + configSettings.cleanUrl(categoryLvl2.name) + '/' + categoryLvl2.id" [ngClass]="{'': (categoryLvl2.has_subcategory == 'Yes')}" (click)="setHideSubMenu(1)" (mouseover)="setHideSubMenu(0)">
                                                        {{categoryLvl2.name}}
                                                    </a>
                                                </div>
                                                <ul *ngIf="categoryLvl2.has_subcategory == 'Yes'">
                                                    <li *ngFor="let categoryLvl3 of categoryLvl2.subcategories">
                                                        <a [routerLink]="'/product/category/' + configSettings.cleanUrl(category.name) + '/' + configSettings.cleanUrl(categoryLvl2.name) + '/' + configSettings.cleanUrl(categoryLvl3.name) + '/' + categoryLvl3.id" (click)="setHideSubMenu(1)" (mouseover)="setHideSubMenu(0)">
                                                            {{categoryLvl3.name}}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </li>
                    <!--<li class="">
                        <a [routerLink]="'/brands'" class="sf-without-ul" title="{{'Brands' | translate}}">{{'Brands' | translate}}</a>
                    </li>-->
                    <!-- <li class="">
                        <a [routerLink]="'/editorials'" class="sf-without-ul">{{'Editorials' | translate}}</a>
                    </li> -->

                    <!--<li>
                        <a href="javascript:;" class="sf-with-ul">{{'Categories' | translate}}</a>

                        <div class="megamenu megamenu-md">
                            <div class="row no-gutters">
                                <div class="col-md-12">
                                    <div class="menu-col">
                                        <div class="row">
                                            <div class="col-md-6" *ngFor="let category of categories;let i = index;">
                                                <div class="menu-title">
                                                    <a [routerLink]="'/product/category/' + configSettings.cleanUrl(category.name) + '/' + category.id">
                                                        {{category.name}}
                                                    </a>
                                                </div><!-- End .menu-title

                                                <ul class="mb-2">
                                                    <li *ngFor="let categoryLvl2 of category.subcategories">
                                                        <a [routerLink]="'/product/category/' + configSettings.cleanUrl(category.name) + '/' + configSettings.cleanUrl(categoryLvl2.name) + '/' + categoryLvl2.id">
                                                            {{categoryLvl2.name}}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div><!-- End .col-md-6
                                        </div><!-- End .row
                                    </div><!-- End .menu-col
                                </div><!-- End .col-md-8
                            </div><!-- End .row
                        </div><!-- End .megamenu megamenu-md
                    </li>-->
                </ul><!-- End .menu -->
            </nav><!-- End .main-nav -->
            <!-- <div class="header-center">
                
            </div> -->

            <div class="header-right">
                <div class="header-search header-search-extended header-search-visible">
                    <a href="javascript:;" class="search-toggle" role="button"><i class="icon-search"></i></a>
                    <form [formGroup]="searchForm" (ngSubmit)="onSearchSubmit(0)">
                        <div class="header-search-wrapper search-wrapper-wide">
                            <label for="q" class="sr-only">{{'Search' | translate}}</label>
                            <input type="search" class="form-control" name="q" id="q" placeholder="{{'Search product...' | translate}}"
                            (keyup)="getSearch(item.value)" #item formControlName="q" />
                            <button class="btn btn-primary" type="submit"><i class="icon-search"></i></button>
                        </div><!-- End .header-search-wrapper -->
                    </form>
                    <div *ngIf="categorySuggestions.length || productSuggestions.length" class="suggestions-container">
                        <ul class="suggestions-tab d-flex text-center mb-0">
                            <li (click)="switch('P')" class="p-3" [ngClass]="{'active': productSuggestionTab == 1}">Product</li>
                            <li (click)="switch('C')" class="p-3" [ngClass]="{'active': productSuggestionTab == 0 && categorySuggestionTab == 1 }">Category</li>
                        </ul>

                        <ng-container *ngIf="categorySuggestions.length > 0">
                            <div *ngIf="productSuggestionTab == 0 && categorySuggestionTab == 1">
                                <div *ngFor="let category of categorySuggestions" class="col-md-12">
                                    <div class="suggestion" *ngIf="category.type == 'C'">
                                        <a [routerLink]="'/category/' + getCategorySlug(category) + '/' +category.id" (click)="hideSuggestions()" class="position-relative" title="{{category.name}}">
                                            {{category.name}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="productSuggestions.length > 0">
                            <div *ngIf="productSuggestionTab == 1">
                                <div *ngFor="let product of productSuggestions" class="col-md-12">
                                    <div class="suggestion" *ngIf="product.type == 'P'">
                                        <a [routerLink]="['/product-detail/' + product.id]" (click)="hideSuggestions()" class="position-relative">
                                            {{product.name}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div> -->
                </div><!-- End .header-search -->

                <div class="dropdown cart-dropdown">
                    <a href="javascript:;" [routerLink]="'/cart'" class="dropdown-toggle" role="button">
                        <i class="icon-shopping-cart"></i>
                        <span class="cart-count">{{cartCount}}</span>
                        <!--<span class="cart-txt">$ 164,00</span>-->
                    </a>
                </div><!-- End .cart-dropdown -->
            </div>
        </div><!-- End .container -->
    </div><!-- End .header-middle -->

    <div class="header-bottom sticky-header">
        <div class="container">
            <div class="header-left">
                
            </div><!-- End .header-left -->

            <div class="header-right">
                <!--<i class="la la-lightbulb-o"></i><p>Clearance Up to 30% Off</span></p>-->
            </div>
        </div><!-- End .container -->
    </div><!-- End .header-bottom -->
</header><!-- End .header -->

<!-- Mobile Menu -->
<div class="mobile-menu-overlay" (click)="toggleLeftMenu()"></div><!-- End .mobil-menu-overlay -->

<div class="mobile-menu-container" [ngClass]="{'scrollmenu': (openLeftMenu == 1)}">
    <div class="mobile-menu-wrapper">
        <span class="mobile-menu-close"><i class="icon-close"></i></span>

        <form [formGroup]="searchForm" (ngSubmit)="onSearchSubmit(1)" class="mobile-search">
            <label for="mobile-search" class="sr-only">{{'Search' | translate}}</label>
            <input type="search" class="form-control" name="mobile-search" id="mobile-search" placeholder="{{'Search product...' | translate}}" formControlName="q">
            <button class="btn btn-primary" type="submit"><i class="icon-search"></i></button>
        </form>

        <nav class="mobile-nav">
            <ul class="mobile-menu">
                <li>
                    <a [routerLink]="'/'" (click)="toggleNav()" title="{{'Home' | translate}}">{{'Home' | translate}}</a>
                </li>
                <li>
                    <a [routerLink]="'/brands'" (click)="toggleNav()" title="{{'Brands' | translate}}">{{'Brands' | translate}}</a>
                </li>
               
                <li *ngFor="let category of categories;let i = index;">
                    <ng-container *ngIf="category.slug != 'brands'">
                        <a [routerLink]="'/category/' + getCategorySlug(category) + '/' + category.id"   (click)="toggleNav()" title="{{category.name}}" *ngIf="category.id != 0">
                            {{category.name}}
                            <span class="mmenu-btn" *ngIf="category.subcategories.length > 0" (click)="toggleSubMenu($event)"></span>
                        </a>
                        <a [routerLink]="'/category/' + getCategorySlug(category)"   (click)="toggleNav()" title="{{category.name}}" *ngIf="category.id == 0">
                            {{category.name}}
                            <span class="mmenu-btn" *ngIf="category.subcategories.length > 0" (click)="toggleSubMenu($event)"></span>
                        </a>
                        <!--<a href="javascript:;">{{category.name}}</a>-->
                        <ul>
                            <li *ngFor="let categoryLvl2 of category.subcategories">
                                <a [routerLink]="'/category/' + getCategorySlug(category) + '/' + getCategorySlug(categoryLvl2) + '/' + categoryLvl2.id" (click)="toggleNav()" title="{{categoryLvl2.name}}">
                                    {{categoryLvl2.name}}
                                    <span class="mmenu-btn" *ngIf="categoryLvl2.subcategories.length > 0" (click)="toggleSubMenu($event)"></span>
                                </a>
                                <ul *ngIf="categoryLvl2.subcategories.length > 0">
                                    <li *ngFor="let categoryLvl3 of categoryLvl2.subcategories">
                                        <a [routerLink]="'/category/' + getCategorySlug(category) + '/' + getCategorySlug(categoryLvl3) + '/' + categoryLvl3.id" (click)="toggleNav()" title="{{categoryLvl3.name}}">
                                            {{categoryLvl3.name}}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </ng-container>
                    
                </li><!-- End .col-md-6 -->
                <!-- <li>
                    <a href="javascript:;">{{'Categories' | translate}}</a>
                    <ul>
                        <li *ngFor="let category of categories;let i = index;">
                            <a href="javascript:;">{{category.name}}</a>
                            <ul>
                                <li *ngFor="let categoryLvl2 of category.subcategories">
                                    <a [routerLink]="'/product/category/' + configSettings.cleanUrl(category.name) + '/' + configSettings.cleanUrl(categoryLvl2.name) + '/' + categoryLvl2.id" (click)="toggleNav()">
                                        {{categoryLvl2.name}}
                                    </a>
                                </li>
                            </ul>
                        </li><!-- End .col-md-6
                    </ul>
                </li> -->

                <li>
                    <ng-container *ngIf="user; else mobileLogin;">
                        <a href="javascript:;">{{'Account' | translate}}</a>
                        <ul>
                            <li>
                                <a href="javascript:;" [routerLink]="'/user-account-menu'" (click)="toggleNav()">{{'Account Menu' | translate}}</a>
                            </li>
                            <li>
                                <a href="javascript:;" (click)="showLogoutModal()">{{'Logout' | translate}}</a>
                            </li>
                        </ul>
                    </ng-container>
                    <ng-template #mobileLogin>
                        <a href="#signin-modal" data-toggle="modal" (click)="toggleNav()" title="{{'Login' | translate}}"><i class="icon-user"></i>{{'Login' | translate}}</a>
                    </ng-template>
                </li>
                <!--<li *ngIf="store">
                    <a href="javascript:;">{{store['currency_' + lang]}}</a>
                    <ul>
                        <li *ngFor="let storeOption of stores">
                            <a href="javascript:;" *ngIf="storeOption.id !== store.id"
                               (click)="changeStore($event, storeOption.id)">{{storeOption['currency_' + lang]}}</a>
                        </li>
                    </ul>
                </li> --><!-- End .header-dropdown -->

                <li>
                    <a (click)="setLanguage()" class="language-change-link" href="javascript:;">{{(lang === 'en') ? 'العربية' : 'English'}}</a>
                </li><!-- End .header-dropdown -->
            </ul>
        </nav><!-- End .mobile-nav -->

        <div class="social-icons" *ngIf="settings != undefined">
            <a href="{{settings.facebook_link}}" class="social-icon" target="_blank" title="Facebook"><i class="icon-facebook-f"></i></a>
            <a href="{{settings.twitter_link}}" class="social-icon" target="_blank" title="Twitter"><i class="icon-twitter"></i></a>
            <a href="{{settings.instagram_link}}" class="social-icon" target="_blank" title="Instagram"><i class="icon-instagram"></i></a>
            <a href="{{settings.youtube_link}}" class="social-icon" target="_blank" title="Youtube"><i class="icon-youtube"></i></a>
        </div><!-- End .social-icons -->
    </div><!-- End .mobile-menu-wrapper -->
</div><!-- End .mobile-menu-container -->

<div class="modal fade" id="signin-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="icon-close"></i></span>
                </button>

                <div class="form-box">
                    <div class="form-tab">
                        <ul class="nav nav-pills nav-fill" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="signin-tab" data-toggle="tab" href="#signin" role="tab" aria-controls="signin" aria-selected="true">{{'Sign In' | translate}}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="register-tab" data-toggle="tab" href="#register" role="tab" aria-controls="register" aria-selected="false">{{'Register' | translate}}</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="tab-content-5">
                            <div class="tab-pane fade show active" id="signin" role="tabpanel" aria-labelledby="signin-tab">
                                <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
                                    <div class="form-group">
                                        <label for="singin-email">{{'Email address' | translate}} *</label>
                                        <input type="text" class="form-control" id="singin-email" name="email" formControlName="email">
                                        <small class="text-danger" *ngIf="loginSubmitted && loginForm.controls.email.errors?.required">
                                            {{'Email is required' | translate}}</small>
                                        <small class="text-danger" *ngIf="loginSubmitted && loginForm.controls['email'].hasError('pattern')">
                                            {{'Invalid Email Address' | translate}}
                                        </small>
                                    </div><!-- End .form-group -->

                                    <div class="form-group">
                                        <label for="singin-password">{{'Password' | translate}} *</label>
                                        <input type="password" class="form-control" id="singin-password" name="password" formControlName="password">
                                        <small class="text-danger" *ngIf="loginSubmitted && loginForm.controls.password.errors?.required">
                                            {{'Password is required' | translate}}
                                        </small>
                                    </div><!-- End .form-group -->

                                    <div class="form-footer">
                                        <button type="submit" class="btn btn-outline-primary-2">
                                            <span>{{'LOG IN' | translate}}</span>
                                            <i class="{{(lang === 'en') ? 'icon-long-arrow-right' : 'icon-long-arrow-left'}}"></i>
                                        </button>

                                        <!--<div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="signin-remember">
                                            <label class="custom-control-label" for="signin-remember">Remember Me</label>
                                        </div>--><!-- End .custom-checkbox -->

                                        <a href="javascript:;" [routerLink]="'/forgot-password'" (click)="openForgotPassword()" class="forgot-link">{{'Forgot Your Password?' | translate}}</a>
                                    </div><!-- End .form-footer -->
                                </form>
                                <div class="form-choice">
                                    <p class="text-center">{{'or sign in with' | translate}}</p>
                                    <div class="row">
                                        <div class="col-sm-6 position-relative">
                                            <a href="javascript:;" (click)="signInWithGoogle()" class="btn btn-login btn-g">
                                                <i class="icon-google"></i>
                                                {{'Login With Google' | translate}}
                                            </a>
                                            <a href="javascript:;" id="btn-g" class="position-absolute" style="top: 0;opacity: 0;"></a>
                                        </div><!-- End .col-6 -->
                                        <div class="col-sm-6">
                                            <a href="javascript:;" (click)="signInWithFB()" class="btn btn-login btn-f">
                                                <i class="icon-facebook-f"></i>
                                                {{'Login With Facebook' | translate}}
                                            </a>
                                        </div><!-- End .col-6 -->
                                    </div><!-- End .row -->
                                </div><!-- End .form-choice -->
                            </div><!-- .End .tab-pane -->
                            <div class="tab-pane fade" id="register" role="tabpanel" aria-labelledby="register-tab">
                                <form [formGroup]="registerForm" (ngSubmit)="onRegisterSubmit()">
                                    <div class="form-group">
                                        <label for="register-full_name">{{'Full name' | translate}} *</label>
                                        <input type="text" class="form-control" id="register-full_name" name="full_name" formControlName="full_name">
                                        <small class="text-danger" *ngIf="registerSubmitted && registerForm.controls.full_name.errors?.required">
                                            {{'First name is required' | translate}}
                                        </small>
                                    </div>

                                    <!-- <div class="form-group">
                                        <label for="register-last_name">{{'Last name' | translate}} *</label>
                                        <input type="text" class="form-control" id="register-last_name" name="last_name" formControlName="last_name">
                                        <small class="text-danger" *ngIf="registerSubmitted && registerForm.controls.last_name.errors?.required">
                                            {{'Last name is required' | translate}}
                                        </small>
                                    </div> -->

                                    <div class="form-group">
                                        <label for="register-email">{{'Email address' | translate}} *</label>
                                        <input type="email" class="form-control" id="register-email" name="email" formControlName="email">
                                        <small class="text-danger" *ngIf="registerSubmitted && registerForm.controls.email.errors?.required">
                                            {{'Email is required' | translate}}</small>
                                        <small class="text-danger" *ngIf="registerSubmitted && registerForm.controls['email'].hasError('pattern')">
                                            {{'Invalid Email Address' | translate}}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label for="register-password">{{'Password' | translate}} *</label>
                                        <input type="password" class="form-control" id="register-password" name="password" formControlName="password">
                                        <small class="text-danger" *ngIf="registerSubmitted && registerForm.controls.password.errors?.required">
                                            {{'Password is required' | translate}}
                                        </small>
                                        <small class="text-danger" *ngIf="registerSubmitted && registerForm.controls.password.errors?.minlength">
                                            {{'Password should contain 6 characters' | translate}}
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <label for="register-confirm_password">{{'Confirm Password' | translate}} *</label>
                                        <input type="password" class="form-control" id="register-confirm_password" name="confirm_password" formControlName="confirm_password">
                                        <small class="text-danger" *ngIf="registerSubmitted && registerForm.controls.confirm_password.errors?.required">
                                            {{'Confirm Password is required' | translate}}
                                        </small>
                                        <small class="text-danger" *ngIf="registerSubmitted && registerForm.controls.confirm_password.errors?.matchPassword">
                                            {{'Please confirm password' | translate}}
                                        </small>

                                    </div>
                                    
                                    <div class="form-group">
                                        <label for="register-phone">{{'Phone Number' | translate}} *</label>
                                        <div class="row">
                                            <div class="col-6 col-sm-5">
                                                <ng-select id="register-phone_code"
                                                [items]="countries" [clearable]=false  
                                                bindLabel="name" bindValue="phonecode" 
                                                formControlName="phone_code">
                                                    <ng-template ng-label-tmp let-item="item">
                                                        <img [src]="item.flag" [alt]="item.name" *ngIf="item.flag != null"/>+{{item.phonecode | uppercase}} - {{item.iso3}}
                                                    </ng-template>
                                                    <ng-template ng-option-tmp let-item="item">
                                                        <img [src]="item.flag" [alt]="item.name"/>+{{ item.phonecode | uppercase }} - {{item.iso3}}
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                            <div class="col-6 col-sm-7">
                                                <input type="text" class="form-control" id="register-phone" name="phone" formControlName="phone" />
                                            </div>
                                        </div>
                                        <small class="text-danger" *ngIf="registerSubmitted && registerForm.controls.phone.errors?.required">
                                            {{'Phone number is required' | translate}}
                                        </small>
                                        <small class="text-danger" *ngIf="registerSubmitted && registerForm.controls.phone.errors?.minlength">
                                            {{'Phone number should contain 8 characters' | translate}}
                                        </small>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label for="register-gender">{{'Gender' | translate}}</label>
                                        <ng-select id="register-gender" [items]="genderOptions"
                                        bindLabel="label" bindValue="value" 
                                        [clearable]=false [searchable]=false formControlName="gender">
                                        </ng-select>
                                    </div><!-- End .form-group -->


                                    <!-- <div class="custom-control custom-checkbox form-group">
                                        <input formControlName="newsletter_subscribed" type="checkbox" class="custom-control-input"
                                               id="checkout-newsletter_subscribed">
                                        <label class="custom-control-label" for="checkout-newsletter_subscribed">
                                            {{'Join us today for exclusive early Sale access plus tailored new arrivals, trends and promotions.' | translate}}
                                        </label>
                                    </div> -->
                                    <!-- End .custom-checkbox -->

                                    <div class="form-footer">
                                        <button type="submit" class="btn btn-outline-primary-2">
                                            <span>{{'SIGN UP' | translate}}</span>
                                            <i class="{{(lang === 'en') ? 'icon-long-arrow-right' : 'icon-long-arrow-left'}}"></i>
                                        </button>
                                    </div><!-- End .form-footer -->
                                </form>
                            </div><!-- .End .tab-pane -->
                        </div><!-- End .tab-content -->
                    </div><!-- End .form-tab -->
                </div><!-- End .form-box -->
            </div><!-- End .modal-body -->
        </div><!-- End .modal-content -->
    </div><!-- End .modal-dialog -->
</div>
